

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SchoolInformation, SchoolUser } from "../firebase/types-school";
import { LatLng } from "leaflet";

interface ISchoolSlice {
  schoolId?: string | null;
  school?: SchoolInformation;
  schoolUser: SchoolUser;
  adminCount?: number;

  parkingLocation?: LatLng;
}

export const initialState: ISchoolSlice = {
  schoolUser: {}
};

export const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {
    setSchoolDefault: (state) => {
      state.school = initialState.school;
      state.schoolUser = initialState.schoolUser;
      state.schoolId = initialState.schoolId;
    },
    clearSchoolData: (state) => {
      state.school = {};
      state.schoolUser = {};
    },
    setSelectedSchool: (state, action: PayloadAction<string | undefined | null>) => {
      state.schoolId = action.payload;
    },
    setSchool: (state, action: PayloadAction<SchoolInformation>) => {
      state.school = action.payload;
    },
    setSchoolUser: (state, action: PayloadAction<SchoolUser>) => {
      state.schoolUser = action.payload;
    },
    setParkingLocation: (state, action: PayloadAction<LatLng|undefined>) => {
      state.parkingLocation = action.payload;
    },
    setAdminCount: (state, action: PayloadAction<number>) => {
      state.adminCount = action.payload;
    }
  },
});

export const {
  setSchoolDefault,
  clearSchoolData,
  setSelectedSchool,
  setSchool,
  setSchoolUser,
  setParkingLocation,
  setAdminCount
} = schoolSlice.actions;

export default schoolSlice.reducer;
