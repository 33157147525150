import { Input, styled, TextField } from "@mui/material";
import styles from "./index.module.scss";
import pxToRem from "../../helpers/pxToRem";

export const UserRegTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        '&:hover fieldset': {
            borderColor: styles.primaryYellow500,
            color: styles.primaryYellow500,
        },
        "&.Mui-focused fieldset": {
            borderColor: styles.primaryYellow500,
            color: styles.primaryYellow500,
        },
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.primaryYellow500}`
        },
        "&::before": {
            // borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.primaryYellow500}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.primaryYellow500}`,
        },
    },
    '&:hover label': {
        // color: styles.primaryYellow500,
        // color: styles.primaryYellow500,
    },
    '& label.Mui-focused': {
        color: styles.primaryYellow500,
        // color:'#00000099'
    },
})

export const OptionalTextField = styled(TextField) ({
    backgroundColor: styles.neutrals100,
    color: styles.neutrals500,
    padding: pxToRem(16),
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20), /* 142.857% */
    letterSpacing:pxToRem(-0.28),
})

export const ProfileTextField = styled(TextField) ({
    color: styles.neutrals500,

    backgroundColor: styles.shades0,

    borderRadius: pxToRem(16),
    border: `1px solid #B0B0B0`,

    padding: pxToRem(16),
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20), /* 142.857% */
    letterSpacing:pxToRem(-0.28),
})

export const SchoolRegTextField = styled(TextField)({
    color: styles.neutrals500,
    fontSize: pxToRem(16),
    fontWeight: 400, 
    lineHeight: pxToRem(24),
    letterSpacing: pxToRem(-0.32),

    "& .MuiOutlinedInput-root": {
        '&:hover fieldset': {
            borderColor: styles.primaryPurple400,
            color: styles.primaryPurple400,
        },
        "&.Mui-focused fieldset": {
            borderColor: styles.primaryPurple400,
            color: styles.primaryPurple400,
        },
    },
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.primaryPurple400}`
        },
        "&::before": {
            // borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.primaryPurple400}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.primaryPurple400}`,
        },
    },
    '&:hover label': {
        // color: styles.primaryYellow500,
        // color: styles.primaryYellow500,
    },
    '& label.Mui-focused': {
        color: styles.primaryPurple400,
        // color:'#00000099'
    },
})


export const LoginTextField = styled(TextField) ({
    // Label Styling
    '& label': {
        color: styles.neutrals500,
        'path': {
            transition:'fill 200ms',
            fill: styles.neutrals500,
        }
    },
    '&:hover label': {
        color: styles.neutrals500,
        'path': {
            fill: styles.neutrals500,
        }
    },
    '&:hover label.Mui-error': {
        color: styles.error_red,
        'path': {
            fill: styles.error_red,
        }
    },
    '& label.Mui-focused': {
        textAlign:'center',
        color: styles.shades100,
        'path': {
            fill: styles.shades100,
        },
        '&.Mui-error': {
            color: styles.error_red,
            'path': {
                fill: styles.error_red,
            }
        }
        // color:'#00000099'
    },
    '& label.Mui-error': {
        'path': {
            fill: styles.error_red,
        }
    },
    '& .MuiSvgIcon-root': {
        fill: styles.neutrals500,
    },

    '.MuiOutlinedInput-notchedOutline > legend > span': {
        paddingRight: 0,
        marginRight: '0.5ch',
    },

    // Input Styling
    "& .MuiOutlinedInput-root": {
        '&:hover fieldset': {
            borderColor: styles.primaryYellow500,
        },
        "&.Mui-focused fieldset": {
            borderColor: styles.primaryYellow500,
        },
        '&.Mui-error fieldset': {
            borderColor: styles.error_red,
        }
    },

    // '&:hover .MuiSvgIcon-root': {
    //     fill: styles.neutrals500,
    // }
})

export const LoginIconTextField = styled(TextField) ({
    // Label Styling
    '& label': {
        color: styles.neutrals500,
        'path': {
            transition:'fill 200ms',
            fill: styles.neutrals500,
        }
    },
    '&:hover label': {
        color: styles.neutrals500,
        'path': {
            fill: styles.neutrals500,
        }
    },
    '&:hover label.Mui-error': {
        color: styles.error_red,
        'path': {
            fill: styles.error_red,
        }
    },
    '& label.Mui-focused': {
        textAlign:'center',
        color: styles.shades100,
        'path': {
            fill: styles.shades100,
        },
        '&.Mui-error': {
            color: styles.error_red,
            'path': {
                fill: styles.error_red,
            }
        }
        // color:'#00000099'
    },
    '& label.Mui-error': {
        'path': {
            fill: styles.error_red,
        }
    },
    '& .MuiSvgIcon-root': {
        fill: styles.neutrals500,
    },

    '.MuiOutlinedInput-notchedOutline > legend > span': {
        // paddingRight: 0,
        marginRight: '-1.3ch',
    },

    // Input Styling
    "& .MuiOutlinedInput-root": {
        '&:hover fieldset': {
            borderColor: styles.primaryYellow500,
        },
        "&.Mui-focused fieldset": {
            borderColor: styles.primaryYellow500,
        },
        '&.Mui-error fieldset': {
            borderColor: styles.error_red,
        }
    },

    // '&:hover .MuiSvgIcon-root': {
    //     fill: styles.neutrals500,
    // }
});

export const SchoolMainInput = styled(Input) ({
    "& .MuiInputBase-root": {
        "&::after": {
            borderBottom: `2px solid ${styles.primaryBlue500}`
        },
        "&::before": {
            // borderBottom: `1px solid ${styles.neutrals400}`
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `1px solid ${styles.primaryBlue500}`,
        },
        '&.Mui-focused:after': {
            borderBottom: `${pxToRem(2)} solid ${styles.primaryBlue500}`,
        },
    },
    '&:hover label': {
        // color: styles.primaryYellow500,
        // color: styles.primaryYellow500,
    },
    '& label.Mui-focused': {
        color: styles.primaryBlue500,
        // color:'#00000099'
    },
})