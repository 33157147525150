import moment from "moment";
import { convert1DArrayTo2DArray } from "../helpers/convert1DArrayTo2DArray";

interface IBatchRequests<T> {
  asyncFuncs: (() => Promise<T>)[];
  count: number;
  errorMsg: string;
}

// Used for Large amount requests, becuase browser has limition on simoutaneous request
export const batchRequests = async <T>({
  asyncFuncs,
  count,
  errorMsg,
}: IBatchRequests<T>) => {
  try {
    const async2DFuncs = convert1DArrayTo2DArray(asyncFuncs, count);
    const res = await Promise.all(
      async2DFuncs.map((asyncFuncs) =>
        Promise.all(asyncFuncs.map((asyncFunc) => asyncFunc()))
      )
    );

    return { code: 200, value: res.flat() };
  } catch (error) {
    console.log(`Errors happen when trying to ${errorMsg}.`);
    console.log(error);
    return { code: 500 };
  }
};

export const getUserStoragePath = (id: string) => `/${id}/public/profilePicture_${moment().format("DD-MM-YYYY-HH:mm:ss")}`;
export const getUserDocStoragePath = (id: string, name: string) => `/${id}/private/${name}`;
export const getReferenceStoragePath = (id: string) => `/references/${id}`;