import { PlaceType } from "../../../components/AutoComplete";


export const isValidPlaceType = (place: PlaceType) => {
    let address = place.address_components;
    let geometry = place.geometry;

    if(!address || !geometry) return false;
    
    let countryObj = address.filter(el => el.types.includes('country'))[0];
    let stateObj = address.filter(el => el.types.includes('administrative_area_level_1'))[0];
    let suburbObj = address.filter(el => el.types.includes('locality'))[0];
    let postal_codeObj = address.filter(el => el.types.includes('postal_code'))[0];

    if(!countryObj || !countryObj.long_name) return false;
    if(!stateObj || !stateObj.short_name) return false;
    if(!suburbObj || !suburbObj.long_name) return false;
    if(!postal_codeObj || !postal_codeObj.long_name) return false;

    return true;
}