import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SchoolInformation, SchoolUser } from "../firebase/types-school";

// export interface SchoolUpdate {
//   user?: {path?: string, data: any};
//   teacher?: {path?: string, data: any};
//   // path:{userPath?:string, teacherPath?: string};
//   // data:{userData?: any, teacherData?: any};
// }

interface ISchoolRegSlice {
    fields: SchoolInformation;
    newReg: SchoolInformation;
    schoolUser: SchoolUser;
    firstTime: boolean;
    step: number;
    regModalOpen: boolean;
    goToStep?: string;
    isNewSchool?: boolean;
    reset?: boolean;
}

export const initialState: ISchoolRegSlice = {
  fields: {},
  newReg: {},
  schoolUser: {},
  firstTime: false,
  step: 0,
  regModalOpen: false
};

export const schoolRegSlice = createSlice({
  name: "schoolReg",
  initialState,
  reducers: {
    setSchoolRegDefault: (state) => {
        state.fields = initialState.fields;
        state.newReg = initialState.newReg;
        state.schoolUser = initialState.schoolUser;
        state.firstTime = initialState.firstTime;
        state.step = initialState.step;
        state.regModalOpen = initialState.regModalOpen;
    },
    setSchoolRegFields: (state, action: PayloadAction<SchoolInformation>) => {
        state.fields = action.payload;
    },
    setSchoolNewReg: (state, action: PayloadAction<SchoolInformation>) => {
        state.newReg = action.payload;
    },
    setSchoolUser: (state, action: PayloadAction<SchoolUser>) => {
        state.schoolUser = action.payload;
    },
    setSchoolFirstTime: (state, action: PayloadAction<boolean>) => {
        state.firstTime = action.payload;
    },
    setSchoolStep: (state, action: PayloadAction<number>) => {
        state.step = action.payload;
    },
    setOpenSchoolRegModal: (state, action: PayloadAction<boolean>) => {
        state.regModalOpen = action.payload;
    },
    setGoToStep: (state, action: PayloadAction<string|undefined>) => {
        state.goToStep = action.payload;
    },
    setIsNewSchool: (state, action: PayloadAction<boolean|undefined>) => {
        state.isNewSchool = action.payload;
    },
    resetSchoolReg: (state, action: PayloadAction<boolean|undefined>) => {
        state.reset = action.payload;
        if(state.reset) {
            state.regModalOpen = initialState.regModalOpen;
            state.newReg = initialState.newReg;
            state.schoolUser = initialState.schoolUser;
            state.isNewSchool = initialState.isNewSchool;
        }
    }
  },
});

export const {
    setSchoolRegDefault,
    setSchoolRegFields,
    setSchoolNewReg,
    setSchoolUser,
    setSchoolFirstTime,
    setSchoolStep,
    setOpenSchoolRegModal,
    setGoToStep,
    setIsNewSchool,
    resetSchoolReg
} = schoolRegSlice.actions;

export default schoolRegSlice.reducer;
