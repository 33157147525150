import Stack from "@mui/material/Stack";
import styles from "./index.module.scss";
import FooterBannerPng from "../../assets/images/footer/banner.png";
import SparkLogoPng from "../../assets/images/footer/High-Res-Spark-Full-Logo-768x176.png";
import facebookIconPng from "../../assets/images/footer/facebookIcon.png";
import instagramIconPng from "../../assets/images/footer/instagramIcon.png";
import linkedInIconPng from "../../assets/images/footer/linkedInIcon.png";
import { DASH_FOOTER_CONFIGS, FOOTER_CONFIGS } from "./constants";
import FooterButton from "./FooterButton";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabNames } from "../../routers/type";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";


import { useEffect, useState } from "react";
import { getTermsData } from "../../firebase/general-apis";
import { sortTermsBasedOnRank } from "../../pages/TermsOfServicePage";
import moment from "moment";
import { TermData } from "../TermsPreviewBox/types";
import { Button, Menu, MenuItem } from "@mui/material";
import { setUserHomeState } from "../../redux/userSlice";
import pxToRem from "../../helpers/pxToRem";

import KeppleLogo from '../../assets/images/share/KeppleSquareLogo.png';

interface IFooter {
  dash?: boolean;
  teacherSchool?: boolean;
}
const Footer = (props: IFooter) => {
  const { state } = useParams();
  const screenType = useSelector((state: RootState) => state.ui.screenType);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLocation = useSelector((state: RootState) => state.user.homeState);
  const [selectedState, setSelectedState] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const selectStateOpen = Boolean(anchorEl);

  const [terms, setTerms] = useState<TermData[]>([]);

  const getTerms = async () => {
    const termsData = await getTermsData();
    let terms = Object.entries(termsData)
      .sort(sortTermsBasedOnRank)
      .map(([uid, term]) => ({ ...term, uid }));
    setTerms(terms.filter(term => {
      const effectiveDateTimeStamp = moment(term.effectiveDate, 'DD-MM-YYYY').unix();
      const expiryDateTimeStamp = moment(term.expiryDate, 'DD-MM-YYYY').unix();
      return effectiveDateTimeStamp < moment().unix() && (term.expiryDate.length === 0 || expiryDateTimeStamp > moment().unix())
    }))
  }

  const handleStateChange = (e: string) => {
    dispatch(setUserHomeState(e));
    navigate(`${location.pathname.split('/').slice(0, -1).join('/') + `/${e.toLocaleLowerCase()}`}`);
    handleClose();
  }
  // const handleStateChange = (e: any) => {
  //   dispatch(setUserHomeState(e.target.value));
  //   navigate(`${location.pathname.split('/').slice(0, -1).join('/') + `/${e.target.value.toLocaleLowerCase()}`}`);
  // }

  useEffect(() => {
    getTerms();
  }, []);

  useEffect(() => {
    let paths = location.pathname.split('/');
    let path = paths.length > 0 ? paths[paths.length - 1].toLocaleUpperCase() : undefined;
    setSelectedState(userLocation || path);
  }, [userLocation, location.pathname]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      className={`${props.dash ? styles['footer--dash'] : styles.footer}`}alignItems='center'
    >
      <Stack className={styles.footerContainer} alignItems='center'>
        <img
          src={SparkLogoPng}
          alt='Spark Relief Teachers Logo'
          className={styles.logo}
          onClick={() => navigate("/")}
        />
        <Stack direction='row' className={styles.buttonStack}>
          {
            props.teacherSchool && selectedState !== undefined ?
              <>
                <Button
                  id="basic-button"
                  aria-controls={selectStateOpen ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={selectStateOpen ? 'true' : undefined}
                  onClick={handleClick}
                  className={`${styles.footerButton} ${screenType === "PC"
                    ? "title_medium bold_weight"
                    : screenType === "TABLET"
                      ? "label_medium medium_weight"
                      : `${styles.mobile_button_size} medium_weight`
                    }`}

                >
                  <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                    <>Location - {selectedState}</>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6 9L12 15L18 9" stroke="#003545" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /> </svg>
                  </Stack>
                </Button>
                <Menu open={selectStateOpen}
                  id="basic-menu"
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={() => handleStateChange('VIC')}>Location - VIC</MenuItem>
                  <MenuItem onClick={() => handleStateChange('NSW')}>Location - NSW</MenuItem>
                </Menu>
              </>
              :
              null
          }
          {
            props.dash && terms.length > 0 ?
              DASH_FOOTER_CONFIGS.map((config) => (
                <a href={terms.filter(el => el.title === config.text)[0]?.url || ''} key={`footer-${config.text}-button`} target={config.openInNewTab ? "_blank" : undefined}
                  style={{ textDecoration: 'underline', textDecorationColor: 'inherit', color: styles.shades100 }}
                >{config.text}</a>
                // <FooterButton {...config} key={`footer-${config.text}-button`} />
              ))
              :
              FOOTER_CONFIGS.map((config) => (
                <FooterButton {...config} key={`footer-${config.text}-button`} />
              ))}

          {location.pathname.includes("/shop") ? (
            <FooterButton
              link={`/${TabNames.SHOP}/${TabNames.SHOP_TERMS}`}
              text={"Spark Shop Policies"}
              openInNewTab={false}
            />
          ) : null}
        </Stack>


        <Stack direction={"row"} className={styles.socialMediasStack}>
          <IconButton
            onClick={() =>
              window.open("https://www.facebook.com/sparkreliefteachers")
            }
          >
            <img src={facebookIconPng} alt='facebookIconPng' />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://www.instagram.com/sparkreliefteachers/?utm_medium=copy_link"
              )
            }
          >
            <img src={instagramIconPng} alt='instagramIconPng' />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/spark-relief-teachers/about/?viewAsMember=true"
              )
            }
          >
            <img src={linkedInIconPng} alt='linkedInIconPng' />
          </IconButton>
        </Stack>
        <div
          className={`${styles.footerCompanyInfo} ${screenType === "MOBILE" ? styles.mobile_button_size : "label_small"
            } medium_weight`}
        >
          © 2024 Spark Relief Teachers <br />
          ABN 47 654 729 932
        </div>
      </Stack>
      <Stack className={styles.kepple}>
        <div />
        <Stack direction={'row'} alignItems='center' gap={pxToRem(12)} sx={{cursor: 'pointer'}}  className={styles.keppleText} onClick={() => window.open('https://www.kepple.com.au', '_blank')}>
          <img src={KeppleLogo} alt="Kepple Logo"/>
          <small>Powered by Kepple</small>
        </Stack>
        <div />
      </Stack>
    </Stack>
  );
};

export default Footer;
