import { Box, IconButton, Stack } from "@mui/material";
import styles from "./PhotoUpload.module.scss";
import { ReactNode, useRef, useState } from "react";
import { removeStorageFile, uploadFileToStorageWithProgress } from "../../../../firebase/general-apis";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { TaskState } from "firebase/storage";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { v4 } from "uuid";
import pxToRem from "../../../../helpers/pxToRem";
import { getUserStoragePath } from "../../../../firebase/helpers";

interface IFileUpload {
    url?: string;
    file: {id: string, path: string, file: any, mimeType?: string}[];
    setFile: (files: {id: string, path: string, file: any, mimeType?: string}[]) => void;
    label?: string;
    path: string;
    onSuccess?: (url: string)=>Promise<void>;
    accept?: string;
    icon?: ReactNode;
}

const MIME_TYPES = ["application/pdf", "image/png", "image/jpeg"]

const MultipleFileUpload = ({file, setFile, label, path, accept, icon}: IFileUpload) => {
    // const [file, setFile] = useState<{id: number, file:any}[]>(props.file!==undefined && props.file.map((el,i) => {return {id:i, file:el}}) || []);
    const [errorStr, setErrorStr] = useState<string>();
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const userId = useSelector((state: RootState) => state.user.userId);

    const [uploadState, setUploadState] = useState<TaskState>();
    
    const readFile = async (file: any) => {
        if(file){
            const source = file.files[0];
            const size = file.files[0].size;
            const fileSizeLimit = 1000; //MB
            if((size/1024/1024) < fileSizeLimit){
                if(MIME_TYPES.includes(source.type)){
                    let id = v4();
                    const res = await uploadFileToStorageWithProgress({path: `${path}${id}`, file: source, onProgress: (state: TaskState, progress: number)=>{setUploadState(state)}});
                    setUploadState(undefined);
                    if(res.code === 500) { setErrorStr("Upload failed. Please try again."); return; }
                    // if(props.onSuccess) await props.onSuccess(res.data[1]);
                    addFile(id, `${path}${id}`, res.data[1], source.type);
                    setErrorStr(undefined);
                }
                else{
                    setErrorStr("Unsupported file type.");
                }
            }
            else{
                // setErrorStr("Max file size limit.");
                setErrorStr("Max File Size Exceeded");
            }
        }
        else{
            setErrorStr("File not found.");
        }
    }

    const addFile = (id: string, path: string, newFile: any, mimeType: string) => {
        setFile([...file, {id: id, path: path, file: newFile, mimeType}])
    }
    const removeFile = (id: string) => {
        if(!userId) {return;}
        let tempFile = file.filter(f => f.id === id);
        if(tempFile) {
            setFile(file.filter(f => f.id !== id));
            // Do not actually delete file (need to keep for record)
            // await removeStorageFile(`${path}${id}`);
        }
    }

    const drag = (event: React.DragEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if(event.type === "dragenter" || event.type === "dragover"){ setDragActive(true); }
        else if (event.type === "dragleave") { setDragActive(false); }
    }
    const drop = (event: React.DragEvent<HTMLInputElement>)  => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            readFile(event.dataTransfer);
        }
    }

    const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if(event.target.files && event.target.files[0]){
            readFile(event.target);
        }
    }

    // const reset = () => {
    //     setFile([]);
    //     setErrorStr(undefined);
    //     setUploadState(undefined);
    // }



    return (
        <>
            {
                file.length>0 && 
                <Stack gap={pxToRem(12)}>
                    {
                        file.map((el, i) => (
                            <Stack className={styles.uploadSuccess}>
                                <small><a href={el.file} target="_blank" style={{textDecoration:'none', color: 'inherit'}}>Document ({i+1}) was successfully uploaded</a></small>
                                <IconButton onClick={() => {removeFile(el.id)}}><svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.477 24.6086H20.532C21.8419 24.6086 22.6069 23.9106 22.6642 22.6102L23.2666 8.79348H24.5862C25.0738 8.79348 25.4467 8.41101 25.4467 7.93293C25.4467 7.45484 25.0642 7.09149 24.5862 7.09149H20.4364V5.65723C20.4364 4.12736 19.4515 3.21899 17.8069 3.21899H14.1734C12.5288 3.21899 11.544 4.12736 11.544 5.65723V7.09149H7.41329C6.9352 7.09149 6.55273 7.4644 6.55273 7.93293C6.55273 8.42057 6.9352 8.79348 7.41329 8.79348H8.74237L9.35432 22.6102C9.40213 23.9106 10.1671 24.6086 11.477 24.6086ZM13.5328 5.74329C13.5328 5.31301 13.8292 5.03572 14.3073 5.03572H17.6826C18.1607 5.03572 18.4571 5.31301 18.4571 5.74329V7.09149H13.5328V5.74329ZM12.9304 21.6731C12.5479 21.6731 12.2802 21.4245 12.2706 21.0421L11.9838 10.8875C11.9742 10.505 12.242 10.2469 12.6436 10.2469C13.026 10.2469 13.2938 10.4955 13.3033 10.8779L13.5902 21.0325C13.6093 21.415 13.3416 21.6731 12.9304 21.6731ZM15.9997 21.6731C15.5981 21.6731 15.3304 21.4245 15.3304 21.0421V10.8779C15.3304 10.505 15.5981 10.2469 15.9997 10.2469C16.4013 10.2469 16.6786 10.505 16.6786 10.8779V21.0421C16.6786 21.4245 16.4013 21.6731 15.9997 21.6731ZM19.069 21.6827C18.6579 21.6827 18.3902 21.415 18.4093 21.0421L18.6961 10.8779C18.7057 10.4955 18.9734 10.2469 19.3559 10.2469C19.7575 10.2469 20.0252 10.505 20.0156 10.8875L19.7288 21.0421C19.7192 21.4245 19.4515 21.6827 19.069 21.6827Z" fill="#555555"/> </svg> </IconButton>
                            </Stack>
                        ))
                    }
                </Stack>
            }
            {
                !uploadState?
                    <Stack className={`${styles.uploadContainer}`}>
                        {label!==undefined ? <small>{label}</small>:null}
                        <Stack spacing={1} className={`${styles.uploadPictureBox} ${dragActive?styles.uploadPictureBoxActive:''}`} onClick={()=>{ inputRef.current?.click() }} onDragLeave={drag} onDragEnter={drag} onDragOver={drag} onDrop={drop}>
                            <input ref={inputRef} type='file' multiple={false} onChange={fileChange} style={{ display: "none" }} accept={accept?accept:undefined}/>
                            { icon? icon :<svg width="118" height="88" viewBox="0 0 118 88" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M81.7328 29.6537C94.1139 30.8169 105.136 33.3837 114.333 38.5939C126.422 45.4428 96.9756 48.0521 95.361 58.0917C93.6099 68.9797 123.492 82.5794 106.669 86.2481C91.476 89.5612 77.1342 83.0174 61.9441 79.6993C42.5102 75.454 8.89388 75.9184 1.96493 64.0114C-5.17383 51.7438 21.1084 44.5334 37.8561 36.5126C51.1665 30.1381 65.0339 28.0847 81.7328 29.6537Z" fill="#F5F5F5"/> <path d="M86.9277 14.5398V56.1398C86.9277 57.2169 86.0547 58.0898 84.9777 58.0898H43.3777C42.3008 58.0898 41.4277 57.2169 41.4277 56.1398V14.5398C41.4277 13.4629 42.3008 12.5898 43.3777 12.5898H84.9777C86.0547 12.5898 86.9277 13.4629 86.9277 14.5398Z" fill="white" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M77.1767 2.84082H33.6268C32.5498 2.84082 31.6768 3.71387 31.6768 4.79082V48.3408" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M41.4277 44.4403L59.122 38.5903L86.9277 48.3403" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M72.3018 32.0903C69.6095 32.0903 67.4268 29.9076 67.4268 27.2153C67.4268 24.523 69.6095 22.3403 72.3018 22.3403C74.9941 22.3403 77.1768 24.523 77.1768 27.2153C77.1768 29.9076 74.9941 32.0903 72.3018 32.0903Z" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg> }
                            {/* { fileName?<label className={styles.uploadMaxsizeTitle}>{fileName}</label>:null } */}
                            <Stack direction={"row"} spacing={0.5}>
                                <label className={styles.uploadTitleBig}>Choose file</label>
                                <label className={styles.uploadTitleSmall}>or drag and drop</label>
                            </Stack>
                            <label className={styles.uploadMaxsizeTitle}>Accepts PDF, JPG or PNG</label>
                            { errorStr?<label className={styles.uploadError}>{errorStr}</label>:null }
                        </Stack> 
                    </Stack>
                :
                    <LoadingSpinner/>
            }
            
        </>
    );
};

export default MultipleFileUpload;