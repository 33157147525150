import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { RootState } from "../redux/store";
import { useMemo } from "react";
import { SchoolAccountState, SchoolUserAccountState, SchoolUserAuthority } from "../firebase/types-school";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";

interface IRoute {
  children: JSX.Element;
  customizedFallbackRoute?: string;
}

export function RestrictedRoute({ children, customizedFallbackRoute }: IRoute) {
  const isLogined = useSelector((state: RootState) => state.user.login);

  console.log(isLogined);

  return isLogined ? (
    <Navigate to={`${customizedFallbackRoute ?? "/"}`} replace />
  ) : (
    children
  );
}

export function ProctedRoute({ children, customizedFallbackRoute }: IRoute) {
  const isLogined = useSelector((state: RootState) => state.user.login);
  const userDelete = useSelector((state: RootState) => state.userReg.userDelete);
  const sessionLogout = useSelector((state: RootState) => state.user.sessionLogout);

  if(isLogined === undefined) {
    return <></>
  }

  return isLogined || userDelete || sessionLogout ? (
    children
  ) : (
    <Navigate to={`${customizedFallbackRoute ?? "/"}`} replace />
  );
}

export const SchoolPermissionRoute = ({ children, customizedFallbackRoute }: IRoute) => {
  const schoolId = useSelector((state: RootState) => state.school.schoolId);
  const school = useSelector((state: RootState) => state.school.school);
  const schoolUser = useSelector((state: RootState) => state.school.schoolUser);
  const navigate = useNavigate();
  const canAccessMySchool = useMemo(() => {
    if (!schoolId || !school?.account?.state ||  !schoolUser.school?.accountState || !schoolUser.school?.authority) return undefined;
    switch (school?.account?.state as SchoolAccountState) {
      case SchoolAccountState.APPROVED: case SchoolAccountState.PENDING: {
        if (schoolUser.school?.accountState === SchoolUserAccountState.APPROVED || schoolUser.school?.authority === SchoolUserAuthority.ADMIN) {
          return true;
        }
        return false;
      }
      case SchoolAccountState.REMOVED: {
        return false;
      }
      default: return false;
    }
  }, [school?.account?.state, schoolUser.school?.accountState, schoolUser.school?.authority])
  return canAccessMySchool === undefined?
  <LoadingSpinner/>
  : 
    canAccessMySchool?
    children
    :
    <Navigate to={`${customizedFallbackRoute ?? "/"}`} />
}