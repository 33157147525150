export const primaryIdentity = [
    {value: "passport", label: "Passport"},
    {value: "birthCertificate", label: "Birth Certificate"},
    {value: "australianCitizenshipCertificate", label: "Australian Citizenship Certificate"}
]

export const secondaryIdentity = [
    ...primaryIdentity,
    {value: "driversLicence", label: "Drivers Licence"},
    // {value: "marriageCertificate", label: "Marriage Certificate"},
    {value: "governmentEmployeeIdentityCard", label: "Government Employee Identity Card"},
    {value: "workingWithChildrens", label: "Working with Childrens"}
]

export const WORKING_RIGHTS = [
    {value: "unrestrictedCitizen", label: "Australian Citizen"},
    {value: "unrestrictedPermanentResident", label: "Permanent Resident"},
    {value: "temporaryVisaHolder", label: "Temporary Working Visa"}
]

export const formatContactNumber = (contact: string) => {
    if(/^0[0-9]{3} [0-9]{3} [0-9]{3}$/.test( contact )) {
        return contact;
    }
    let updatedContact = contact;
    if(updatedContact.indexOf(' ') !== 4) {
        updatedContact = updatedContact.substring(0,4) + ' ' + updatedContact.substring(4);
    }
    if(updatedContact.substring(5).indexOf(' ') !== 3) {
        updatedContact = updatedContact.substring(0,8) + ' ' + updatedContact.substring(8);
    }
    return updatedContact;
}

export const formatOnChange = (value: string) => {
    // Remove all non-digit characters
    value = value.replace(/\D/g, '');

    // Split the value into chunks based on the desired format
    if (value.length > 4 && value.length <= 7) {
      value = value.replace(/(\d{4})(\d+)/, '$1 $2');
    } else if (value.length > 7) {
      value = value.replace(/(\d{4})(\d{3})(\d+)/, '$1 $2 $3');
    }

    return value;
  };


export const formatLandlineOnChange = (value: string) => {
    // Remove all non-digit characters
    value = value.replace(/\D/g, '');

    // Apply the 2 (with brackets) 4 4 format
    if (value.length > 2 && value.length <= 6) {
    value = value.replace(/(\d{2})(\d+)/, '($1) $2');
    } else if (value.length > 6) {
    value = value.replace(/(\d{2})(\d{4})(\d+)/, '($1) $2 $3');
    } else if (value.length > 2) {
    value = `(${value.substring(0, 2)}) ${value.substring(2)}`;
    } else if (value.length > 0) {
    value = `(${value}`;
    }

    return value;
};