import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TscreenType } from "../hooks/useGetScreenType";

interface IUiSlice {
  screenType: TscreenType;
  incompleteEmailSettingModalVisible: boolean;
  registerAs?: 'Teacher'|'School';

  errorOpen: boolean;
  errorMsg?: string;
}

const initialState: IUiSlice = {
  screenType: "PC",
  incompleteEmailSettingModalVisible: false,

  errorOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setScreenType: (state, action: PayloadAction<TscreenType>) => {
      state.screenType = action.payload;
    },
    setIncompleteEmailSettingModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.incompleteEmailSettingModalVisible = action.payload;
    },
    setRegisterAs: (state, action: PayloadAction<'Teacher'|'School'|undefined>) => {
      state.registerAs = action.payload;
    },
    setErrorOpen: (state,
      action: PayloadAction<boolean>
    ) => {
      state.errorOpen = action.payload;
    },
    setErrorMsg: (state, action: PayloadAction<string|undefined>
    ) => {
      state.errorMsg = action.payload;
    }
  },
});

export const { 
  setScreenType, 
  setIncompleteEmailSettingModalVisible, 
  setRegisterAs,
  setErrorOpen,
  setErrorMsg
} =
  uiSlice.actions;

export default uiSlice.reducer;
