import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import styles from "../index.module.scss";

export function loadScript(src: string, position: HTMLElement | null, id: string, callback?: () => void) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  if(callback !== undefined) {
    script.onload = callback;
  }
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
export interface PlaceType {
  description: string;
  formatted_address?: string;
  structured_formatting: StructuredFormatting;
  place_id?: string;
  address_components?: google.maps.GeocoderAddressComponent[];
  geometry?: {
    location: {
      lat: ()=>number;
      lng: ()=>number;
    }
  }
  name?: string;
  vicinity?: string;
}
interface IGoogleMapsTextField {
  inputValue: string;
  setInputValue: (value: string) => void;
  value: PlaceType | null;
  setValue: (place: PlaceType | null) => void;
  error?: boolean;
  helperText?: string;
  label?:string;

  location?: { lat: number, lng: number};
}
const GoogleMapsTextField = ({inputValue, setInputValue, value, setValue, error, helperText, label, location}: IGoogleMapsTextField) => {
  // const [value, setValue] = React.useState<PlaceType | null>(null);
  // const [inputValue, setInputValue] = React.useState(defaultValue || '');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=en-AU&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string, location?: google.maps.LatLng, language?: string },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  const fetchPlaceDetails = (placeId: string): Promise<PlaceType> => {
    return new Promise((resolve, reject) => {
      const service = new (window as any).google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({ placeId }, (place: PlaceType, status: any) => {
        if (status === (window as any).google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        } else {
          reject(status);
        }
      });
    });
  };

  const handleChange = async (event: any, newValue: PlaceType | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
  //   description: string;
  // structured_formatting: StructuredFormatting;
    if (newValue && newValue.place_id) {
      const details = await fetchPlaceDetails(newValue.place_id);
      setValue({
        ...newValue, 
        ...details 
      });
    } else {
      setValue(newValue);
    }
  };

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    // Bias search location to user, otherwise bias to australia

    fetch({ input: inputValue, 
      ...(location ? 
          { locationBias: new google.maps.Circle({ center: new google.maps.LatLng(location.lat, location.lng), radius: 100000 }) } 
          : { locationBias: new google.maps.Circle({ center: new google.maps.LatLng(-25.6095, 134.3617), radius: 100000 }) } 
      ),
      language: 'en-AU'
    }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: '100%', 
        // "& .MuiOutlinedInput-root":{"&:hover fieldset": {borderColor:styles.primaryYellow500}} 
        "& .MuiOutlinedInput-root": {
            '&:hover > fieldset': {
                borderColor: styles.primaryYellow500,
                color: styles.primaryYellow500,
            },
            "&.Mui-focused fieldset": {
                borderColor: styles.primaryYellow500,
                color: styles.primaryYellow500,
            },
        },
        '&:hover label': {
            // color: styles.primaryYellow500,
        },
        '& label.Mui-focused': {
            color: styles.primaryYellow500,
        },
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={{
        ...(value? {description: value.description}: {description: inputValue}),
        ...(value? {structured_formatting: value.structured_formatting}: {structured_formatting: {}})
      } as PlaceType | null}
      noOptionsText="No locations"
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label={label?label:'Home Location'} sx={{}} fullWidth error={error} helperText={helperText}/>
      )}
      isOptionEqualToValue={(option, value) => option.description === value.description}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );
        return (
          <li key={key} {...optionProps}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part: any, index: number) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export {GoogleMapsTextField}