import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../index.module.scss";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDownIcon from "@mui/icons-material/ArrowRight";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import { Menu, MenuItem } from "@mui/material";

interface IHeaderButton {
  text: string;
  link: string;
  isSelected?: boolean;
}

const HeaderButton = ({ text, link }: IHeaderButton) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      variant='text'
      onClick={() => navigate(link)}
      className={`${styles.headerButton_text} ${styles.headerButton} ${location.pathname.includes(link)
          ? styles["headerButton--selected"]
          : "neutral_black"
        }`}
      sx={{ fontFamily: "Lexend Deca  !important" }}
    >
      {text}
    </Button>
  );
};

export default HeaderButton;

interface IDropdownHeaderButton extends IHeaderButton {
  customizedIcon?: JSX.Element;
  options?: {text: string, link: string}[],
  handleClose?: () => void;
}

export const DropdownHeaderButton = ({
  text,
  link,
  isSelected,
  customizedIcon,
  options,
  handleClose
}: IDropdownHeaderButton) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  return (
    <MenuItem onClick={options !== undefined ? () => setOpen(!open) : () => navigate(link)}>
    <Stack>
    <Button
      variant='text'
      // onClick={options !== undefined ? () => setOpen(!open) : () => navigate(link)}
      className={
        isSelected
          ? styles["DropdownHeaderButton--selected"]
          : styles.DropdownHeaderButton
      }
    >
      <Stack
        direction={"row"}
        alignItems='center'
        className={styles.DropdownHeaderButton__stack}
      >
        {isSelected ? <CircleIcon /> : customizedIcon ?? <ArrowRightIcon />}
        <div
          className={`title_large ${isSelected ? "semi_bold_weight" : "regular_weight"
            }`}
        >
          {text}
        </div>
      </Stack>
    </Button>
    {
      options && 
      <Stack className={`${styles.dropdownContainer} ${open?styles['dropdownContainer--open']:''}`}>
          {options.map(el => (
            <Button onClick={() => {navigate(el.link); handleClose && handleClose();}}>{el.text}</Button>
          ))}
      </Stack>
    }
    </Stack>
    </MenuItem>
  );
};

interface IMenuHeaderButton {
  text: string;
  link: string;
  config: {name: string, text: string, link: string}[],
  isSelected?: boolean;
}
export const MenuHeaderButton = ({
  text,
  link,
  config,
  isSelected,
}: IMenuHeaderButton) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open =  Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const trigger = (text: string) => {
    console.log(`${text} triggered`);
  }

  return (
    <>
      <Button
        variant='text'
        onClick={handleClick}
        className={`${styles.headerButton_text} ${styles.headerButton} ${window.location.pathname.includes(link)
            ? styles["headerButton--selected"]
            : "neutral_black"
          }`}
        sx={{ fontFamily: "Lexend Deca  !important" }}
      >
        {text}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          className: styles.menuListcontainer,
          'aria-labelledby': 'basic-button',
          onMouseLeave: handleClose
        }}
        slotProps={{
          paper: {
              className: styles.dotButtonMenu
          },
        }}
      >
        {
          config.map(el => (
            <MenuItem className={styles.hoverMenuItem} onClick={() => {handleClose(); navigate(el.link)}}>{el.text}</MenuItem>
          ))
        }
      </Menu>
    </>
  );
}