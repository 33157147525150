import { Box, Stack } from "@mui/material";
import pxToRem from "../../../../helpers/pxToRem";
import { RegModalWrapper } from "../ModalContent";
import styles from "../index.module.scss";
import { useEffect, useState } from "react";
import { getUserDocStoragePath } from "../../../../firebase/helpers";
import samplePhoto from "../../../../assets/images/teacher-registration/sample-photo-holding-id.png";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import FileUpload from "./FileUpload";
import moment from "moment";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { getIncompleteState } from "../../ReviewPage/helper";
import { updateUserInfo } from "../../../../firebase/general-apis";

const PhotoHoldingID = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const userId = useSelector((state: RootState) => state.user.userId);
    const [photoUrl, setPhotoUrl] = useState(reg.holdingIdPhotoURL?.value);

    const isAllComplete = () => {
        return photoUrl !== '' && photoUrl !== undefined;
    }

    const handleUpdate = () => {
        let updateData: UserUpdate = {}
        if(reg.holdingIdPhotoURL && reg.holdingIdPhotoURL.id) {
            updateData = {
                teacher: {
                    path: `/identity/${reg.holdingIdPhotoURL.id}`,
                    data: {
                        ...(reg.holdingIdPhotoURL.documentType ? null: {documentType: 'photoHolidingIdentification'}),
                        ...(reg.holdingIdPhotoURL.value ? 
                                reg.holdingIdPhotoURL.value !== photoUrl && photoUrl !== '' ?
                                    {value:photoUrl?photoUrl:null}
                                : null
                            : photoUrl!== undefined && photoUrl !== '' ? {value: photoUrl} : {value: null}),
                        ...(!photoUrl ? {state: 'incomplete'} : {state: 'submitted'})
                    }
                }
            }
        } else if(photoUrl !== undefined && photoUrl !== '') {
            updateData = {
                teacher: {
                    path: '/identity',
                    data: {
                        selfieWithIdentification: {
                            documentType: "photoHolidingIdentification",
                            value: photoUrl,
                            state: "submitted"
                        }
                    }
                }
            }
        }

        console.log(updateData);
        dispatch(updateFields(updateData));
    }

    const onUploadSuccess = async (url: string, mimeType: string) => { 
        if(!userId) {return}
        await updateUserInfo(userId, {
            teacher: { 
                path: `/identity/${reg.holdingIdPhotoURL?.id || 'selfieWithIdentification'}`,
                data: {
                    documentType: 'photoHolidingIdentification',
                    value: url,
                    type: mimeType,
                    state: 'submitted'
            }}
        })
        setPhotoUrl(url); }
    const handleDelete = async () => { 
        if(!userId) {return}
        await updateUserInfo(userId, {
            teacher: { 
                path: `/identity/${reg.holdingIdPhotoURL?.id || 'selfieWithIdentification'}`,
                data: {
                    documentType: null,
                    value: null,
                    type: null,
                    state: null
                }
            }
        })
        setPhotoUrl(undefined); 
    }

    useEffect(() => {
        reg.holdingIdPhotoURL?.value?setPhotoUrl(reg.holdingIdPhotoURL?.value):setPhotoUrl(undefined);
    }, [reg.holdingIdPhotoURL])

    return (
        <>
            <RegModalWrapper title="Identity Verification - Photo Holding ID" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    holdingIdPhotoURL: reg.holdingIdPhotoURL,
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Photo Holding ID</h5>
                        <p className={styles.p}>Please upload a photo of yourself holding one of your identity documents</p>
                    </Stack>
                    <Stack className={styles.profileContainer}>
                        <Box className={styles['profilePhoto--left']}>
                            {userId && 
                                <FileUpload
                                    file={photoUrl} path={getUserDocStoragePath(userId, `photoOfSelfWithIdentification-${moment().format("DD-MM-YYYY-HH:mm:ss")}`)}
                                    label="" onSuccess={onUploadSuccess} accept={'image/png, image/jpeg'} handleDelete={handleDelete} image url={photoUrl}
                                />
                            }
                        </Box>
                        <Stack className={styles['profilePhoto--right2']}>
                            <small style={{alignSelf:'flex-end', color: styles.shades100}}>Example*</small>
                            <img src={samplePhoto}/>
                        </Stack>
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default PhotoHoldingID;