import { useDispatch, useSelector } from "react-redux";
import { EmployeeDocsModalWrapper, RegModalWrapper } from "../ModalContent";
import { RootState } from "../../../../redux/store";
import pxToRem from "../../../../helpers/pxToRem";
import { Stack } from "@mui/material";
import styles from "../index.module.scss";
import FileUpload from "./FileUpload";
import { useEffect, useState } from "react";
import { getUserDocStoragePath } from "../../../../firebase/helpers";
import moment from "moment";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { getIncompleteState } from "../../ReviewPage/helper";
import MultipleFileUpload from "./MultipleFileUpload";
import { FieldState, TeacherAccountState } from "../../../../firebase/types-teacher";
import { updateUserInfo } from "../../../../firebase/general-apis";
import { LightTooltip } from "../../../../components/Tooltip";


const TaxDetails = () => {
    const dispatch = useDispatch();
    const reg = useSelector((state: RootState) => state.userReg.fields);
    const userId = useSelector((state: RootState) => state.user.userId);
    const [docUrl, setDocUrl] = useState<{ id: string, path: string, file: any, mimeType?: string }[]>(Object.entries(reg.tax?.docs || {}).map(([id, el]) => { return { id: id, path: getUserDocStoragePath(userId || '', `taxDetails-${id}`), file: el.value, mimeType: el.type } }));

    const accountState = useSelector((state: RootState) => state.userReg.fields.accountState?.value);

    const handleUpdateFile = async (files: { id: string, path: string, file: any, mimeType?: string }[]) => {
        if(!userId) {return;}
        setDocUrl(files);

        let updatedFiles:any = {};
        for(let f of files) {
            if(f.id !== 'value') {
                updatedFiles[f.id] = {
                    submitted: 'web',
                    value: f.file,
                    type: f.mimeType
                }
            }
        }

        await updateUserInfo(userId, {
            teacher: { path: "/tax", data:{ 
                ...updatedFiles,
                state: Object.keys(updatedFiles).length>0?"submitted":null
            }}
        }, true);
    }

    const handleUpdate = () => {
        let updateData: UserUpdate = {};

        if (reg.tax) {
            let data:any  = {
                state: docUrl.length > 0 ? 'submitted' : null
            }
            docUrl.map(el => {
                switch(el.id) {
                    case 'value': {
                        data = {
                            ...data,
                            value: el.file,
                            type: el.mimeType,
                        }
                        break;
                    }
                    default: {
                        data = {
                            ...data,
                            [`${el.id}`]: {
                                submitted: 'web',
                                value: el.file,
                                type: el.mimeType
                            }
                        }
                    }
                }
            })

            updateData = {
                teacher: {
                    path: '/',
                    data: {
                        tax: data
                    }
                }
            }
        }

        dispatch(updateFields(updateData));
    }

    useEffect(() => {
        setDocUrl(Object.entries(reg.tax?.docs || {}).map(([id, el]) => { return { id: id, path: getUserDocStoragePath(userId || '', `taxDetails-${id}`), file: el.value, mimeType: el.type } }))
    }, [reg.tax?.docs])

    return (
        <>

            {
                accountState !== TeacherAccountState.INCOMPLETE.valueOf() && reg.tax?.state === 'incomplete' ?
                    <EmployeeDocsModalWrapper title={"Employee Documents - Tax File Number"} handleUpdate={handleUpdate}>
                        <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                            <Stack className={styles.titleContainer}>
                                <h5 className={styles.h5} >Tax File Number</h5>
                                {
                                    reg.tax.state === FieldState.INCOMPLETE ? 
                                    <Stack className={styles.infoBox}>
                                        <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8"/> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white"/> </svg>
                                        <small>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br/><br/>Please download the Federal Government standard form template below, complete it and upload it.</small>
                                    </Stack>
                                    :
                                    <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                        <LightTooltip placement="bottom-start" title={ <p className={styles.p}>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br/><br/>Please download the Federal Government standard form template below, complete it and upload it.</p> }>
                                            <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8"/> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white"/> </svg>
                                        </LightTooltip>
                                        <p className={styles.p}>Why we need this information</p>
                                    </Stack>
                                }
                            </Stack>
                            <a href="https://firebasestorage.googleapis.com/v0/b/spark-a968d.appspot.com/o/0000000000000-PUBLIC%2Fstandard-forms%2FTax%20File%20Number%20Declaration%20Form%20-%20Spark%20Relief%20Teachers.pdf?alt=media&token=d2bc4fae-39f9-4db3-8daa-772edc935e7b"
                                target="_blank" style={{ textDecoration: 'none', width: 'fit-content' }}>
                                <small className={styles.link}>Download blank form</small>
                            </a>
                            {userId &&
                                <MultipleFileUpload
                                    file={docUrl} path={getUserDocStoragePath(userId, `taxDetails-`)}
                                    label="Upload document" setFile={handleUpdateFile}
                                    accept={'image/png, image/jpeg, application/pdf'}
                                />
                            }
                        </Stack>
                    </EmployeeDocsModalWrapper>
                :
                    <RegModalWrapper title="Employee Documents - Tax File Number" handleUpdate={handleUpdate}
                        state={getIncompleteState({
                            tax: reg.tax,
                        })} isAllComplete={docUrl.length > 0}
                    >
                        <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                            <Stack className={styles.titleContainer}>
                                <h5 className={styles.h5} >Tax File Number</h5>
                                {
                                    reg.tax?.state === FieldState.INCOMPLETE ? 
                                    <Stack className={styles.infoBox}>
                                        <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8"/> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white"/> </svg>
                                        <small>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br/><br/>Please download the Federal Government standard form template below, complete it and upload it.</small>
                                    </Stack>
                                    :
                                    <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                        <LightTooltip placement="bottom-start" title={ <p className={styles.p}>To ensure that you are always paid on time, you will need to provide us with your tax details before you start working.<br/><br/>Please download the Federal Government standard form template below, complete it and upload it.</p> }>
                                            <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8"/> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white"/> </svg>
                                        </LightTooltip>
                                        <p className={styles.p}>Why we need this information</p>
                                    </Stack>
                                }
                            </Stack>
                            <a href="https://firebasestorage.googleapis.com/v0/b/spark-a968d.appspot.com/o/0000000000000-PUBLIC%2Fstandard-forms%2FTax%20File%20Number%20Declaration%20Form%20-%20Spark%20Relief%20Teachers.pdf?alt=media&token=d2bc4fae-39f9-4db3-8daa-772edc935e7b"
                                target="_blank" style={{ textDecoration: 'none', width: 'fit-content' }}>
                                <small className={styles.link}>Download blank form</small>
                            </a>
                            {userId &&
                                <MultipleFileUpload
                                    file={docUrl} path={getUserDocStoragePath(userId, `taxDetails-`)}
                                    label="Upload document" setFile={handleUpdateFile}
                                    accept={'image/png, image/jpeg, application/pdf'}
                                    icon={<svg width="82" height="72" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M56.9493 31.4431C65.6361 32.2592 73.3694 34.0602 79.8221 37.7157C88.3042 42.521 67.6439 44.3518 66.511 51.3957C65.2824 59.035 86.2482 68.5767 74.4448 71.1507C63.7852 73.4753 53.7227 68.8841 43.0651 66.556C29.4299 63.5774 5.84414 63.9033 0.982672 55.5491C-4.02601 46.9419 14.414 41.883 26.1645 36.2555C35.5034 31.783 45.233 30.3423 56.9493 31.4431Z" fill="#F5F5F5"/> <path d="M30.2928 53.1971H51.7079C56.0271 53.1971 58.257 50.927 58.257 46.5877V28.3466C58.257 25.5341 57.8954 24.2484 56.1477 22.4604L45.641 11.793C43.9334 10.0654 42.5271 9.66357 39.9959 9.66357H30.2928C25.9937 9.66357 23.7437 11.9337 23.7437 16.293V46.5877C23.7437 50.927 25.9937 53.1971 30.2928 53.1971ZM30.6343 49.1792C28.7057 49.1792 27.7414 48.1747 27.7414 46.3265V16.5341C27.7414 14.706 28.7057 13.6814 30.6544 13.6814H39.1722V24.61C39.1722 27.5229 40.5986 28.9292 43.4914 28.9292H54.2593V46.3265C54.2593 48.1747 53.295 49.1792 51.3463 49.1792H30.6343ZM43.8731 25.4136C43.0294 25.4136 42.6678 25.052 42.6678 24.2283V14.2038L53.7169 25.4136H43.8731ZM48.0718 34.1926H33.5673C32.8039 34.1926 32.2615 34.735 32.2615 35.4381C32.2615 36.1613 32.8039 36.7238 33.5673 36.7238H48.0718C48.795 36.7238 49.3374 36.1613 49.3374 35.4381C49.3374 34.735 48.795 34.1926 48.0718 34.1926ZM48.0718 40.9024H33.5673C32.8039 40.9024 32.2615 41.4649 32.2615 42.1881C32.2615 42.8913 32.8039 43.4337 33.5673 43.4337H48.0718C48.795 43.4337 49.3374 42.8913 49.3374 42.1881C49.3374 41.4649 48.795 40.9024 48.0718 40.9024Z" fill="#FCB218"/> </svg> }
                                />
                            }
                        </Stack>
                    </RegModalWrapper>
            }
        </>
    )
};

export default TaxDetails;