import { GUIDE_TYPES, TabNames } from "../../routers/type";

export const FOOTER_CONFIGS = [
  {
    text: "Blog",
    link: `/${TabNames.BlogsHomePage}`,
    openInNewTab: false,
  },
  {
    text: "Guides",
    link: `/${TabNames.GUIDES}/${GUIDE_TYPES.TEACHER_GUIDES}`,
    openInNewTab: false,
  },
  {
    text: "Help Desk",
    link: `/${TabNames.HELP_DESK}`,
    openInNewTab: false,
  },
  {
    text: "Terms ",
    link: `/${TabNames.TERMS_OF_SERVICE}`,
    openInNewTab: false,
  },
  {
    text: "User Benefits",
    link: `/${TabNames.SPARK_USER_BENEFITS}`,
    openInNewTab: false,
  },
];

export const DASH_FOOTER_CONFIGS = [
  {
    text: "Privacy Policy",
    // link: `/${TabNames.TERMS_OF_SERVICE}`,
    openInNewTab: true,
  },
  {
    text: "Site Terms of Use",
    // link: `/${TabNames.TERMS_OF_SERVICE}`,
    openInNewTab: true,
  },
  {
    text: "Cookie Policy",
    // link: `/${TabNames.TERMS_OF_SERVICE}`,
    openInNewTab: true,
  },
]