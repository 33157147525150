import { Box, MobileStepper, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { FieldState, TeacherAccountState } from "../../firebase/types-teacher";
import styles from './index.module.scss';
import pxToRem from "../../helpers/pxToRem";
import { memo, useEffect } from "react";


const TeacherRegStepper = () => {
    const step = useSelector((state:RootState) => state.userReg.step);
    const steps = useSelector((state:RootState) => state.userReg.stateList);
    const accountState = useSelector((state:RootState) => state.userReg.fields.accountState?.value);

    const renderColor = (label: string) => {
        switch(label as FieldState) {
            case FieldState.INCOMPLETE: {
                return styles.shades0;
                
            }
            // case FieldState.SUBMITTED: {
            //     return styles.primaryBlue800;
            // }
            default: return styles.primaryYellow400;
                
        }
    }

    return (
        <>
            <Stack className={styles.stepperContainer}>
                {
                    steps.map((el, i) => (
                        <Box key={`stepper_${i}`} className={`${styles.step} ${step===i?styles.step__selected:''}`} sx={{background: renderColor(el), borderRadius:'50%', width:step===i?`${2/(steps.length*3)*100}%`:`${1/(steps.length*3)*100}%`, aspectRatio: '1/1', border: `2px solid ${styles.primaryYellow400}`}}/>
                    ))
                }
            </Stack>
        </>
    )
};

export default TeacherRegStepper;