import { Box, IconButton, Stack } from "@mui/material";
import styles from "./PhotoUpload.module.scss";
import { ReactNode, useEffect, useRef, useState } from "react";
import { uploadFileToStorageWithProgress } from "../../../../firebase/general-apis";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { TaskState } from "firebase/storage";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";

interface IFileUpload {
    url?: string;
    file?: string;
    label?: string;
    path: string;
    onSuccess?: (url: string, mimeType: string)=>Promise<void>;
    accept?: string;
    handleDelete?: () => Promise<void>;
    image?: boolean;
    icon?: ReactNode;
    subtext?: string;
    fileSizeLimitMb?: number;
}

const MIME_TYPES = ["application/pdf", "image/png", "image/jpeg"]

const FileUpload = (props: IFileUpload) => {
    const [file, setFile] = useState<any>(props.file);
    const [fileName, setFileName] = useState<string>();
    const [errorStr, setErrorStr] = useState<string>();
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [uploadState, setUploadState] = useState<TaskState>();
    
    const readFile = async (file: any) => {
        if(file){
            const source = file.files[0];
            const size = file.files[0].size;
            const fileSizeLimit = props.fileSizeLimitMb?props.fileSizeLimitMb:1000; //MB
            if((size/1024/1024) < fileSizeLimit){
                if(MIME_TYPES.includes(source.type)){
                    const res = await uploadFileToStorageWithProgress({path: props.path, file: source, onProgress: (state: TaskState, progress: number)=>{setUploadState(state)}});
                    if(res.code === 500) { setErrorStr("Upload failed. Please try again."); setFileName(undefined); setUploadState('error'); return; }
                    setUploadState('success');
                    if(props.onSuccess) await props.onSuccess(res.data[1], source.type);

                    const reader = new FileReader();
                    reader.addEventListener('load', () => { 
                        setFile(source);
                        setFileName(source.name);
                        setErrorStr(undefined);
                    });
                    reader.readAsDataURL(source);
                }
                else{
                    setErrorStr("Unsupported file type.");
                    setFileName(undefined);
                }
            }
            else{
                // setErrorStr("Max file size limit.");
                setErrorStr("Max File Size Exceeded");
                setFileName(undefined);
            }
        }
        else{
            setErrorStr("File not found.");
            setFileName(undefined);
        }
    }

    const drag = (event: React.DragEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if(event.type === "dragenter" || event.type === "dragover"){ setDragActive(true); }
        else if (event.type === "dragleave") { setDragActive(false); }
    }
    const drop = (event: React.DragEvent<HTMLInputElement>)  => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            readFile(event.dataTransfer);
        }
    }

    const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if(event.target.files && event.target.files[0]){
            readFile(event.target);
        }
    }

    const reset = () => {
        setFile(undefined);
        setFileName(undefined);
        setErrorStr(undefined);
        setUploadState(undefined);
    }

    useEffect(() => {
        props.file?setFile(props.file):setFile(undefined);
    }, [props.file]);

    return (
        <>
            {
                file===undefined ?
                    uploadState ===undefined || uploadState!=="running" ?
                        <Stack className={`${styles.uploadContainer} ${props.image && styles.photoUploadContainer}`}>
                            {props.label!==undefined ? <small>{props.label}</small>:null}
                            <Stack spacing={1} className={`${styles.uploadPictureBox} ${dragActive?styles.uploadPictureBoxActive:''}`} onClick={()=>{ inputRef.current?.click() }} onDragLeave={drag} onDragEnter={drag} onDragOver={drag} onDrop={drop}>
                                <input ref={inputRef} type='file' multiple={false} onChange={fileChange} style={{ display: "none" }} accept={props.accept?props.accept:undefined}/>
                                {
                                    props.icon ? props.icon : <svg width="117" height="88" viewBox="0 0 117 88" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M81.0842 29.6537C93.4653 30.8169 104.487 33.3837 113.684 38.5939C125.774 45.4428 96.327 48.0521 94.7123 58.0917C92.9612 68.9797 122.843 82.5794 106.02 86.2481C90.8273 89.5612 76.4855 83.0174 61.2954 79.6993C41.8615 75.454 8.2452 75.9184 1.31625 64.0114C-5.82252 51.7438 20.4597 44.5334 37.2074 36.5126C50.5178 30.1381 64.3852 28.0847 81.0842 29.6537Z" fill="#F5F5F5"/> <path d="M86.279 14.5398V56.1398C86.279 57.2169 85.4061 58.0898 84.329 58.0898H42.7291C41.6521 58.0898 40.7791 57.2169 40.7791 56.1398V14.5398C40.7791 13.4629 41.6521 12.5898 42.7291 12.5898H84.329C85.4061 12.5898 86.279 13.4629 86.279 14.5398Z" fill="white" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M76.528 2.84082H32.9781C31.9011 2.84082 31.0281 3.71387 31.0281 4.79082V48.3408" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M40.7791 44.4403L58.4733 38.5903L86.279 48.3403" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M71.653 32.0903C68.9607 32.0903 66.778 29.9076 66.778 27.2153C66.778 24.523 68.9607 22.3403 71.653 22.3403C74.3452 22.3403 76.5279 24.523 76.5279 27.2153C76.5279 29.9076 74.3452 32.0903 71.653 32.0903Z" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                }
                                { fileName?<label className={styles.uploadMaxsizeTitle}>{fileName}</label>:null }
                                <Stack direction={"row"} spacing={0.5}>
                                    <label className={styles.uploadTitleBig}>Choose file</label>
                                    <label className={styles.uploadTitleSmall}>or drag and drop</label>
                                </Stack>
                                <label className={styles.uploadMaxsizeTitle}>{props.subtext?props.subtext:'Accepts PDF, JPG or PNG'}</label>
                                { errorStr?<label className={styles.uploadError}>{errorStr}</label>:null }
                            </Stack> 
                        </Stack>
                    :
                        <Stack className={styles.loadingContainer}>
                            <LoadingSpinner width="100%" height='100%'/>
                        </Stack>
                :
                    props.image!== undefined && props.url!==undefined ? 
                        <Stack className={styles.uploadedImage}>
                            <Box className={styles.imgContainer}>
                                <img src={props.url} alt={"Uploaded user profile"}/>
                            </Box>
                            <Stack className={styles.photoActions}>
                                <small>Your photo was successfully uploaded</small>
                                <IconButton onClick={()=>{reset();props.handleDelete && props.handleDelete()}}><svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.478 24.6086H20.533C21.8429 24.6086 22.6079 23.9106 22.6652 22.6102L23.2676 8.79348H24.5871C25.0748 8.79348 25.4477 8.41101 25.4477 7.93293C25.4477 7.45484 25.0652 7.09149 24.5871 7.09149H20.4373V5.65723C20.4373 4.12736 19.4525 3.21899 17.8079 3.21899H14.1744C12.5298 3.21899 11.5449 4.12736 11.5449 5.65723V7.09149H7.41427C6.93618 7.09149 6.55371 7.4644 6.55371 7.93293C6.55371 8.42057 6.93618 8.79348 7.41427 8.79348H8.74335L9.3553 22.6102C9.40311 23.9106 10.168 24.6086 11.478 24.6086ZM13.5338 5.74329C13.5338 5.31301 13.8302 5.03572 14.3083 5.03572H17.6836C18.1616 5.03572 18.4581 5.31301 18.4581 5.74329V7.09149H13.5338V5.74329ZM12.9314 21.6731C12.5489 21.6731 12.2812 21.4245 12.2716 21.0421L11.9848 10.8875C11.9752 10.505 12.2429 10.2469 12.6445 10.2469C13.027 10.2469 13.2947 10.4955 13.3043 10.8779L13.5911 21.0325C13.6103 21.415 13.3425 21.6731 12.9314 21.6731ZM16.0007 21.6731C15.5991 21.6731 15.3314 21.4245 15.3314 21.0421V10.8779C15.3314 10.505 15.5991 10.2469 16.0007 10.2469C16.4023 10.2469 16.6796 10.505 16.6796 10.8779V21.0421C16.6796 21.4245 16.4023 21.6731 16.0007 21.6731ZM19.07 21.6827C18.6589 21.6827 18.3911 21.415 18.4103 21.0421L18.6971 10.8779C18.7067 10.4955 18.9744 10.2469 19.3569 10.2469C19.7585 10.2469 20.0262 10.505 20.0166 10.8875L19.7298 21.0421C19.7202 21.4245 19.4525 21.6827 19.07 21.6827Z" fill="#555555"/> </svg> </IconButton>
                            </Stack>
                        </Stack>
                        // <Stack className={styles.successContainer}>
                        //     <img src={props.url}/>
                        //     <Stack className={styles.uploadSuccess}>
                        //         <small>Your document was successfully uploaded</small>
                        //         <IconButton onClick={() => {reset(); props.handleDelete && props.handleDelete()}}><svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.477 24.6086H20.532C21.8419 24.6086 22.6069 23.9106 22.6642 22.6102L23.2666 8.79348H24.5862C25.0738 8.79348 25.4467 8.41101 25.4467 7.93293C25.4467 7.45484 25.0642 7.09149 24.5862 7.09149H20.4364V5.65723C20.4364 4.12736 19.4515 3.21899 17.8069 3.21899H14.1734C12.5288 3.21899 11.544 4.12736 11.544 5.65723V7.09149H7.41329C6.9352 7.09149 6.55273 7.4644 6.55273 7.93293C6.55273 8.42057 6.9352 8.79348 7.41329 8.79348H8.74237L9.35432 22.6102C9.40213 23.9106 10.1671 24.6086 11.477 24.6086ZM13.5328 5.74329C13.5328 5.31301 13.8292 5.03572 14.3073 5.03572H17.6826C18.1607 5.03572 18.4571 5.31301 18.4571 5.74329V7.09149H13.5328V5.74329ZM12.9304 21.6731C12.5479 21.6731 12.2802 21.4245 12.2706 21.0421L11.9838 10.8875C11.9742 10.505 12.242 10.2469 12.6436 10.2469C13.026 10.2469 13.2938 10.4955 13.3033 10.8779L13.5902 21.0325C13.6093 21.415 13.3416 21.6731 12.9304 21.6731ZM15.9997 21.6731C15.5981 21.6731 15.3304 21.4245 15.3304 21.0421V10.8779C15.3304 10.505 15.5981 10.2469 15.9997 10.2469C16.4013 10.2469 16.6786 10.505 16.6786 10.8779V21.0421C16.6786 21.4245 16.4013 21.6731 15.9997 21.6731ZM19.069 21.6827C18.6579 21.6827 18.3902 21.415 18.4093 21.0421L18.6961 10.8779C18.7057 10.4955 18.9734 10.2469 19.3559 10.2469C19.7575 10.2469 20.0252 10.505 20.0156 10.8875L19.7288 21.0421C19.7192 21.4245 19.4515 21.6827 19.069 21.6827Z" fill="#555555"/> </svg> </IconButton>
                        //     </Stack>
                        // </Stack>
                    :
                        <Stack className={styles.uploadSuccess}>
                            <small>Your document was successfully uploaded</small>
                            <IconButton onClick={() => {reset(); props.handleDelete && props.handleDelete()}}><svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.477 24.6086H20.532C21.8419 24.6086 22.6069 23.9106 22.6642 22.6102L23.2666 8.79348H24.5862C25.0738 8.79348 25.4467 8.41101 25.4467 7.93293C25.4467 7.45484 25.0642 7.09149 24.5862 7.09149H20.4364V5.65723C20.4364 4.12736 19.4515 3.21899 17.8069 3.21899H14.1734C12.5288 3.21899 11.544 4.12736 11.544 5.65723V7.09149H7.41329C6.9352 7.09149 6.55273 7.4644 6.55273 7.93293C6.55273 8.42057 6.9352 8.79348 7.41329 8.79348H8.74237L9.35432 22.6102C9.40213 23.9106 10.1671 24.6086 11.477 24.6086ZM13.5328 5.74329C13.5328 5.31301 13.8292 5.03572 14.3073 5.03572H17.6826C18.1607 5.03572 18.4571 5.31301 18.4571 5.74329V7.09149H13.5328V5.74329ZM12.9304 21.6731C12.5479 21.6731 12.2802 21.4245 12.2706 21.0421L11.9838 10.8875C11.9742 10.505 12.242 10.2469 12.6436 10.2469C13.026 10.2469 13.2938 10.4955 13.3033 10.8779L13.5902 21.0325C13.6093 21.415 13.3416 21.6731 12.9304 21.6731ZM15.9997 21.6731C15.5981 21.6731 15.3304 21.4245 15.3304 21.0421V10.8779C15.3304 10.505 15.5981 10.2469 15.9997 10.2469C16.4013 10.2469 16.6786 10.505 16.6786 10.8779V21.0421C16.6786 21.4245 16.4013 21.6731 15.9997 21.6731ZM19.069 21.6827C18.6579 21.6827 18.3902 21.415 18.4093 21.0421L18.6961 10.8779C18.7057 10.4955 18.9734 10.2469 19.3559 10.2469C19.7575 10.2469 20.0252 10.505 20.0156 10.8875L19.7288 21.0421C19.7192 21.4245 19.4515 21.6827 19.069 21.6827Z" fill="#555555"/> </svg> </IconButton>
                        </Stack>
            }
        </>
    );
};

export default FileUpload;