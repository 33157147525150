import { configureStore } from "@reduxjs/toolkit";
import { loadShopcartFromStorage } from "./helpers";
import { listenerMiddleware } from "./middlewares";
import userReducer, { initialState } from "./userSlice";
import uiReducer from "./uiSlice";
import userRegReducer from "./userRegistrationSlice";
import schoolRegReducer from "./schoolRegistrationSlice";
import schoolReducer from "./schoolSlice";

// const middlewares: never[] = []

const store = configureStore({
  preloadedState: {
    user: {
      ...initialState,
      shopCart: loadShopcartFromStorage() ?? [],
    },
  },
  reducer: {
    user: userReducer,
    ui: uiReducer,
    userReg: userRegReducer,
    schoolReg: schoolRegReducer,
    school: schoolReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    listenerMiddleware.middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
