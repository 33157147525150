import { Backdrop, Box, IconButton, Modal, Slider, Stack, Tooltip } from "@mui/material";
import pxToRem from "../../../../helpers/pxToRem";
import styles from './PhotoUpload.module.scss';
import { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import Cropper, { Area } from 'react-easy-crop'
import { mapValue } from "../../../../helpers/utils";
import { getCroppedImg } from "../../../../helpers/canvasUtils";
import { modalStyle } from "..";
import { PrimaryButton, PrimaryOutlinedButton } from "../../../../components/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";

interface IPhotoUpload { 
    title?:string, 
    subTitle?:string, 
    uploadState:boolean; 
    fileUrl?:string; 
    fileId?:string; 
    open: boolean; close: () => void; 
    upload: (file:File) => Promise<void>; 
    fileDelete?:() => void;
}

const PhotoUpload = (iUploadModal: IPhotoUpload) => {
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [errorStr, setErrorStr] = useState<string|undefined>(undefined);
    const [fileName, setFileName] = useState<string|undefined>(undefined);
    const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation, setRotation] = useState(0);
    const [imgSrc, setImgSrc] = useState<string|undefined>(undefined);
    const [isFileSelected, setIsFileSelected] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area|null>(null);
    const [errorDialog, setErrorDialog] = useState(false);
    const [errorDialogStr, setErrorDialogStr] = useState("");

    const [uploaded, setUploaded] = useState(false);

    const userId = useSelector((state: RootState) => state.user.userId);
    
    const [uploading, setUploading] = useState(false);
    // const [success, setSuccess] = useState(false);
    
    const readFile = (file: any) => {
        if(file){
            const source = file.files[0];
            const size = file.files[0].size;
            const fileSizeLimit = 1000; //MB
            if((size/1024/1024) < fileSizeLimit){
                if(source.type.includes("image/jpeg") || source.type.includes("image/png")){
                    const reader = new FileReader();
                    reader.addEventListener('load', () => { 
                        setImgSrc(reader.result?.toString() || undefined);
                        setFileName(source.name);
                        setIsUploadBtnDisabled(false);
                        setErrorStr(undefined);
                        setIsFileSelected(true);
                    });
                    reader.readAsDataURL(source);
                }
                else{
                    setErrorStr("Unsupported file type.");
                    setFileName(undefined);
                    setIsUploadBtnDisabled(true);
                }
            }
            else{
                // setErrorStr("Max file size limit.");
                setErrorStr("Max File Size Exceeded");
                setFileName(undefined);
                setIsUploadBtnDisabled(true);
            }
        }
        else{
            setErrorStr("File not found.");
            setFileName(undefined);
            setIsUploadBtnDisabled(true);
        }
    }
    const drag = (event: React.DragEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if(event.type === "dragenter" || event.type === "dragover"){ setDragActive(true); }
        else if (event.type === "dragleave") { setDragActive(false); }
    }
    const drop = (event: React.DragEvent<HTMLInputElement>)  => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            readFile(event.dataTransfer);
        }
    }
    const pictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if(event.target.files && event.target.files[0]){
            readFile(event.target);
        }
    }
    const reset = () => {
        setDragActive(false);
        setErrorStr(undefined);
        setFileName(undefined);
        setIsUploadBtnDisabled(true);
        setZoom(1);
        setImgSrc(undefined);
        setIsFileSelected(false);
        setErrorDialog(false);
        setRotation(0);
        setUploading(false);
    }
    const rotateRight = () => {
        setRotation(rotation + 90);
    }
    const rotateLeft = () => {
        setRotation(rotation - 90);
    }
    const uploadFile = async() => {
        setUploaded(true);
        setUploading(true);
        try{
            console.log("Cropping image");
            const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels, rotation);
            if(croppedImage){
                new Compressor(croppedImage, 
                    { 
                        quality: 0.6, 
                        error:(error) => { setErrorDialog(true); setErrorDialogStr(error.message); }, 
                        success: async (compressedResult) => { 
                            await iUploadModal.upload(compressedResult as File);
                        } 
                    } 
                );
                reset();
                // setSuccess(true);
            }
            else{
                setErrorDialog(true);
                setErrorDialogStr("Could not able to cropped image. Please try again.");
            }
        }
        catch(error){
            reset();
            console.log(error);
            setUploading(false);
        }
        setUploading(false);
    }
    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => { setCroppedAreaPixels(croppedAreaPixels) }
    
    // useEffect(() => { 
    //     if(iUploadModal.fileUrl){
    //         setRotation(0);
    //         setIsFileSelected(true);
    //         setImgSrc(iUploadModal.fileUrl);
    //         setFileName(iUploadModal.fileUrl);
    //         setIsUploadBtnDisabled(false);
    //     }
    //     else{
    //         reset();
    //     }
    // }, [iUploadModal.open]);

    const isServerFile = () => {
        if(imgSrc?.startsWith("https://")) return true;
        return false;
    }

    const removeFile = async () => {
        console.log('remove valled');
        if(userId && iUploadModal.fileDelete) {
            console.log('remove');
            iUploadModal.fileDelete();
            reset();
            setUploaded(false);
        }
    }

    return(
        <>
            {  
                    iUploadModal.fileUrl ?
                        <Stack className={styles.uploadedImage}>
                            <Box className={styles.imgContainer}>
                                <img src={iUploadModal.fileUrl} alt={"Uploaded user profile"}/>
                            </Box>
                            <Stack className={styles.photoActions}>
                                {iUploadModal.fileUrl && <small>Your photo was successfully uploaded</small>}
                                <IconButton onClick={removeFile}><svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.478 24.6086H20.533C21.8429 24.6086 22.6079 23.9106 22.6652 22.6102L23.2676 8.79348H24.5871C25.0748 8.79348 25.4477 8.41101 25.4477 7.93293C25.4477 7.45484 25.0652 7.09149 24.5871 7.09149H20.4373V5.65723C20.4373 4.12736 19.4525 3.21899 17.8079 3.21899H14.1744C12.5298 3.21899 11.5449 4.12736 11.5449 5.65723V7.09149H7.41427C6.93618 7.09149 6.55371 7.4644 6.55371 7.93293C6.55371 8.42057 6.93618 8.79348 7.41427 8.79348H8.74335L9.3553 22.6102C9.40311 23.9106 10.168 24.6086 11.478 24.6086ZM13.5338 5.74329C13.5338 5.31301 13.8302 5.03572 14.3083 5.03572H17.6836C18.1616 5.03572 18.4581 5.31301 18.4581 5.74329V7.09149H13.5338V5.74329ZM12.9314 21.6731C12.5489 21.6731 12.2812 21.4245 12.2716 21.0421L11.9848 10.8875C11.9752 10.505 12.2429 10.2469 12.6445 10.2469C13.027 10.2469 13.2947 10.4955 13.3043 10.8779L13.5911 21.0325C13.6103 21.415 13.3425 21.6731 12.9314 21.6731ZM16.0007 21.6731C15.5991 21.6731 15.3314 21.4245 15.3314 21.0421V10.8779C15.3314 10.505 15.5991 10.2469 16.0007 10.2469C16.4023 10.2469 16.6796 10.505 16.6796 10.8779V21.0421C16.6796 21.4245 16.4023 21.6731 16.0007 21.6731ZM19.07 21.6827C18.6589 21.6827 18.3911 21.415 18.4103 21.0421L18.6971 10.8779C18.7067 10.4955 18.9744 10.2469 19.3569 10.2469C19.7585 10.2469 20.0262 10.505 20.0166 10.8875L19.7298 21.0421C19.7202 21.4245 19.4525 21.6827 19.07 21.6827Z" fill="#555555"/> </svg> </IconButton>
                            </Stack>
                        </Stack>
                    :
                        uploaded ?
                            <LoadingSpinner/>
                        :
                        <Stack spacing={1} className={`${styles.uploadPictureBox} ${dragActive?styles.uploadPictureBoxActive:''}`} onClick={()=>{ inputRef.current?.click() }} onDragLeave={drag} onDragEnter={drag} onDragOver={drag} onDrop={drop}>
                            <input ref={inputRef} type='file' multiple={false} accept='image/png, image/jpeg' onChange={pictureChange} style={{ display: "none" }} />
                            <svg width="118" height="88" viewBox="0 0 118 88" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M81.7328 29.6537C94.1139 30.8169 105.136 33.3837 114.333 38.5939C126.422 45.4428 96.9756 48.0521 95.361 58.0917C93.6099 68.9797 123.492 82.5794 106.669 86.2481C91.476 89.5612 77.1342 83.0174 61.9441 79.6993C42.5102 75.454 8.89388 75.9184 1.96493 64.0114C-5.17383 51.7438 21.1084 44.5334 37.8561 36.5126C51.1665 30.1381 65.0339 28.0847 81.7328 29.6537Z" fill="#F5F5F5"/> <path d="M86.9277 14.5398V56.1398C86.9277 57.2169 86.0547 58.0898 84.9777 58.0898H43.3777C42.3008 58.0898 41.4277 57.2169 41.4277 56.1398V14.5398C41.4277 13.4629 42.3008 12.5898 43.3777 12.5898H84.9777C86.0547 12.5898 86.9277 13.4629 86.9277 14.5398Z" fill="white" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M77.1767 2.84082H33.6268C32.5498 2.84082 31.6768 3.71387 31.6768 4.79082V48.3408" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M41.4277 44.4403L59.122 38.5903L86.9277 48.3403" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> <path d="M72.3018 32.0903C69.6095 32.0903 67.4268 29.9076 67.4268 27.2153C67.4268 24.523 69.6095 22.3403 72.3018 22.3403C74.9941 22.3403 77.1768 24.523 77.1768 27.2153C77.1768 29.9076 74.9941 32.0903 72.3018 32.0903Z" stroke="#FCB218" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/> </svg>    
                            { fileName?<label className={styles.uploadMaxsizeTitle}>{fileName}</label>:null }
                            <Stack direction={"row"} spacing={0.5}>
                                <label className={styles.uploadTitleBig}>Choose file</label>
                                <label className={styles.uploadTitleSmall}>or drag and drop</label>
                            </Stack>
                            <label className={styles.uploadMaxsizeTitle}>JPG or PNG only</label>
                            { errorStr?<label className={styles.uploadError}>{errorStr}</label>:null }
                        </Stack>  
            }
            {/* <Divider />
            <Stack direction="row" justifyContent="space-between">
                <SecondaryButton onClick={() => { reset(); iUploadModal.close(); }} className={`${styles.button} ${styles.uploadFooterButton}`}>Cancel</SecondaryButton>
                <PrimaryButton onClick={uploadFile} loading={iUploadModal.uploadState} disabled={isUploadBtnDisabled || iUploadModal.uploadState} className={`${styles.button} ${styles.uploadFooterButton}`}>{ isServerFile()?"Save":"Upload"}</PrimaryButton>
            </Stack> */}

            <Modal open={isFileSelected}>
                <Stack sx={{...modalStyle, height: 'fit-content',maxHeight:'90%'}} className={styles.editModal}>
                    <IconButton onClick={reset} disabled={uploading}>
                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="32" cy="32" r="32" fill="#E3E3E3"/> <path d="M44 20L20 44M20 20L44 44" stroke="#555555" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                    </IconButton>
                    <Stack sx={{overflowY: 'scroll', overflowX:'hidden'}}>
                        <Stack gap={pxToRem(12)} alignItems={'flex-start'}>
                            <h4 className={styles.h4} style={{color: styles.shades100, margin: 0}}>Edit your photo</h4>
                            <p className={styles.p}>This image will be printed onto your Spark ID once your account is approved</p>
                        </Stack>
                        <Stack spacing={1} className={`${styles.pictureCropBox}`}>
                            <Backdrop open={uploading} sx={{position: 'absolute', height: '100%', width: '100%', zIndex:10, borderRadius:'8px'}}/>
                            <Stack className={styles.photoEdit}>
                                <Tooltip title="Rotate Left" placement="right">
                                    <IconButton onClick={rotateLeft} size="small">
                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="26" cy="26" r="26" transform="matrix(-1 0 0 1 52 0)" fill="#C465A8"/> <path d="M37.5557 37.5554V35.5332C37.5557 30.6794 37.5557 28.2525 36.6111 26.3986C35.7802 24.7679 34.4543 23.4421 32.8236 22.6112C30.9697 21.6666 28.5428 21.6666 23.689 21.6666H14.4446M14.4446 21.6666L21.6668 28.8888M14.4446 21.6666L21.6668 14.4443" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                    </IconButton>
                                </Tooltip>
                                <Stack className={styles.pictureCropBoxContainer}> 
                                    <Cropper onCropComplete={onCropComplete} classes={{ containerClassName: styles.cropContainer }} image={imgSrc} cropShape="round" rotation={rotation} crop={crop} zoom={zoom} aspect={1} onCropChange={setCrop} onZoomChange={setZoom} objectFit="cover"/>
                                </Stack>
                                <Tooltip title="Rotate Right" placement="right">
                                    <IconButton onClick={rotateRight} size="small">
                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="26" cy="26" r="26" transform="matrix(-1 0 0 1 52 0)" fill="#C465A8"/> <path d="M14.4443 37.5554V35.5332C14.4443 30.6794 14.4443 28.2525 15.3889 26.3986C16.2198 24.7679 17.5457 23.4421 19.1764 22.6112C21.0303 21.6666 23.4572 21.6666 28.311 21.6666H37.5554M37.5554 21.6666L30.3332 28.8888M37.5554 21.6666L30.3332 14.4443" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Stack>
                                <Stack style={{ justifyContent:'center', alignItems:'center', marginTop: pxToRem(8), position:'relative'}}>
                                    {/* <Stack className={styles.button} direction={"row"} spacing={1} style={{ right:pxToRem(-50), position:'absolute', top:0 }}>
                                        { iUploadModal.fileUrl?<IconButton onClick={iUploadModal.fileDelete} size="small"><svg xmlns="http://www.w3.org/2000/svg" width={pxToRem(24)} height={pxToRem(24)} viewBox="0 0 24 24" fill="none"> <path d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6M10 10.5V15.5M14 10.5V15.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/> </svg></IconButton> : null }
                                    </Stack> */}
                                    <Stack direction={"row"} spacing={2} alignItems={'center'}>
                                        <IconButton disabled={zoom<=1} onClick={()=>setZoom(zoom - 0.1)} size="small"> <svg width="26" height="2" viewBox="0 0 26 2" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 1H25" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg> </IconButton>
                                        <Slider className={styles.zoomSlider} size="small" value={zoom} step={0.1} min={1} max={3} valueLabelDisplay="auto" valueLabelFormat={(v) => `${mapValue(v, 1, 3, 0, 100)}`} onChange={(e, v) => { if(typeof(v) ==="number") setZoom(v) }}/>
                                        <IconButton disabled={zoom>=3} onClick={()=>setZoom(zoom + 0.1)} size="small"> <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M13.5 1V25M1.5 13H25.5" stroke="#555555" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </svg> </IconButton>
                                    </Stack>
                                    <label className={styles.ZoomLabel}>Zoom</label>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack className={styles.editModalBtns}>
                            <PrimaryOutlinedButton onClick={reset} loading={uploading} sx={{maxHeight:'36px'}}>REPLACE PHOTO</PrimaryOutlinedButton>
                            <PrimaryButton onClick={uploadFile} loading={uploading} sx={{maxHeight:'36px'}}>SAVE PHOTO</PrimaryButton>
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>
        </>
    );
}

export default PhotoUpload;