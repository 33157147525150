import { get, getDatabase, ref, update } from "firebase/database"
import { v4 } from "uuid"
import { Res, TeacherRequest, TeacherRequestType } from "./types";
import { ConfirmedPlacementState, TeacherAccountState, TeacherAvailability, TeacherAvailabilityStateForDate, TeacherPlacement, TeacherPlacementKeys, TeacherPlacementState } from "./types-teacher";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import { PlaceType } from "../components/AutoComplete";
import { updateProfile, User } from "firebase/auth";
import { auth } from "./configs";
import { AccreditationDocument } from "../redux/types";
dayjs.extend(customParseFormat);

export const createTeacherRequest = async (request: TeacherRequest) => {
    try {
        const docRef = ref(getDatabase(), `adminNotifications/teacherRequest/new/${v4()}`);
        await update(docRef, request);
        return {code: 200}
    } catch (error: any) {
        return {code: 500, error: error, errorMsg: error.code?error.code:null}
    }
}

export const deleteTeacherAccount = async (id: string, homeCountry?: string, homeState?: string) => {
    try {
        if(!homeCountry || !homeState) { return {code: 200} }
        // MARK: - FETCH TEACHER AVAILABILITY AND MARK DATES AVAILABLE AS UNAVAILABLE (availableTeachers)
        const res = await fetchAvailability(id, homeCountry, homeState);
        if(res.code===500) { throw new Error("Failed to fetch teacher availability")};
        for(let [key, value] of Object.entries(res.data)) {
            if(value as TeacherAvailabilityStateForDate === TeacherAvailabilityStateForDate.available) {
                const res2 = await setAvailabilityForDate(id, homeCountry, homeState, key, TeacherAvailabilityStateForDate.unavailable);
                if(res2.code === 500) {throw new Error("Failed to remove teacher availability")}
                
                await new Promise((res, _) => setTimeout(res, 200));
            }
        }
        // MARK: - Set Teacher's individual availability to null
        const removeRes = await removeTeacherAvailability(id, homeCountry, homeState);
        if(removeRes.code===500) { throw new Error("Failed to fetch set teacher availability")};
        console.log("TEACHER_DELETE: Set unavailability complete");
        // MARK: - CANCEL ANY CONFIRMED UPCOMING BOOKINGS
        const placementRes = await fetchPlacementKeys(id, homeCountry, homeState);
        if(placementRes.code === 500) { throw new Error("Failed to fetch teacher placement keys")};
        console.log(placementRes.data);
        let date = dayjs();
        for(let [year, p] of Object.entries(placementRes.data)) {
            if(parseInt(year) >= date.year()) {
                for(let [date, placement] of Object.entries(p)) {
                    if(dayjs(date, "DD-MM-YYYY").isSame(date) || dayjs(date, "DD-MM-YYYY").isAfter(date)) {
                        for(let [pId, pContent] of Object.entries(placement)) {
                            await teacherCancelPlacementForDeleteAccount(id, homeCountry, homeState, pId, pContent)
                            // add delay between calls to avoid cloud functions crash
                            await new Promise((res, _) => setTimeout(res, 200));
                        }
                    }
                }
            }
        }
        console.log("TEACHER_DELETE: Cancelled all upcoming placements");
        // MARK: - UPDATE TEACHER ACCOUNT STATE & REMOVE TOKENS & FAVOURITE SCHOOLS
        const teacherRef = ref(getDatabase(), `teachers/${id}`);
        await update(teacherRef, {
            accountState: {
                value: TeacherAccountState.DELETED.valueOf(),
                deletedBy: id,
                deletedOnDate: moment().format("DD-MM-YYYY HH:mm:ss")
            },
            token: null,
            tokens: null,
            favouriteSchools: null
        });
        console.log("TEACHER_DELETE: Updated Account state, remove tokens and favourite schools");

        return {code: 200}
    } catch (error: any) {
        console.log(`${error}`);
        return {code :500, error: error}
    }
}

export const fetchAvailability = async (id: string, homeCountry: string, homeState: string): Promise<Res<TeacherAvailability>> => {
    try {
        const docRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/teachers/availability/${id}`);
        const snap = await get(docRef);
        if(snap.exists()) {
            let availability: TeacherAvailability = {};
            Object.entries(snap.val()).map(([key, value]) => {
                availability = {
                    ...availability,
                    [`${key}`]: value as string
                }
            })
            return {code: 200, data: availability};
        }
        return {code: 200, data: {} as TeacherAvailability}
    } catch (error: any) {
        console.log("Failed to fetch user availability");
        return {code: 500, error: error, errorMsg: "Failed to fetch user availability"}
    }
}

const getMonthString = (month: number) => {
    if(month <= 8) {
        return `0${month+1}`;
    }
    return `${month+1}`
}

export const setAvailabilityForDate = async (teacherUid: String, homeCountry: String, homeState: string, date: string, state: TeacherAvailabilityStateForDate) => {
    try {
        // Remove availability from availableTeacher node - NOTE(BEWARE): This will trigger a cloud function to reflect changes to relative schools!!
        let d = dayjs(date, "DD-MM-YYYY")
        const docRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/availableTeachers/${d.year()}/${getMonthString(d.month())}/${date}/${teacherUid}`)
        await update(docRef, {
            state: state.valueOf(),
            radius: 0.0,
        });
        console.log(`setting availability for ${date}, ${d.year()} ${getMonthString(d.month())} to ${state.valueOf()}`)
        return {code: 200}
    } catch(error:any) {
        console.log(`Failed to set availability for ${teacherUid} at ${date}`);
        return {code: 500, error: error};
    }
}

export const removeTeacherAvailability = async (teacherUid: string, homeCountry: string, homeState: string) => {
    try {
        // Remove availability from availability/teacher node
        const teacherAvaRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/teachers/availability`);
        await update(teacherAvaRef, {
            [`${teacherUid}`]: {}
        });
        return {code: 200};
    } catch (error: any) {
        console.log(`Failed to reset availability for ${teacherUid}`);
        return {code: 500, error: error};
    }
}

export const fetchPlacementKeys = async (teacherUid: string, homeCountry: string, homeState: string): Promise<Res<TeacherPlacementKeys>> => {
    try {
        const docRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/teachers/placements/${teacherUid}`);
        const snap = await get(docRef);
        if(snap.exists()) {
            return {code: 200, data: snap.val() as TeacherPlacementKeys}
        }
        return {code:200, data: {}};
    } catch (error:any) {
        return {code: 500, error: error}
    }
}


// NOTE(BEWARE): This action will trigger cloud functions!!
export const teacherCancelPlacementForDeleteAccount = async (teacherUid: string, homeCountry: string, homeState: string, placementId: string, key: TeacherPlacement) => {
    try {
        console.log(`cancelling ${placementId} ${JSON.stringify(key)}`)
        const placementDate = dayjs(key.date, "DD-MM-YYYY");
        console.log(`${placementDate.year()}/${getMonthString(placementDate.month())}/${key.date}/${teacherUid}`)

        const docRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/placements/confirmed/${placementDate.year()}/${getMonthString(placementDate.month())}/${key.date}/${placementId}`);
        await update(docRef, {
            "teacherDetails/state": TeacherPlacementState.cancelled.valueOf(),
            "placementDetails/state": ConfirmedPlacementState.cancelledByTeacher.valueOf(),
            "placementDetails/teacherCancelTimeStamp": moment().format("DD-MM-YYYY HH:mm:ss")
        })
        console.log(`-- confirmed placement`)

        const avaTeachersRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/availableTeachers/${placementDate.year()}/${getMonthString(placementDate.month())}/${key.date}/${teacherUid}`)
        await update(avaTeachersRef, {
            state: TeacherAvailabilityStateForDate.unavailable.valueOf(),
            [`requestedBy/${key.schoolUid}`]: null,
        })
        console.log(`-- availableTeachers`)

        const schoolPlacementsRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/schools/placements/${key.schoolUid}/${placementDate.year()}/${key.date}/${teacherUid}`);
        await update(schoolPlacementsRef, {
            cancelled: true,
            cancelledByTeacher: true
        })
        console.log(`-- school placements`)

        const teacherPlacementsRef = ref(getDatabase(), `countries/${homeCountry}/${homeState}/teachers/placements/${teacherUid}/${placementDate.year()}/${key.date}/${placementId}`);
        await update(teacherPlacementsRef, {
            cancelled: true,
        })
        console.log(`-- teacher placements`)


        console.log(`CANCELLED_PLACEMENT: ${placementId} ${placementDate.format("YYYY-MM-DD")}`)

        return {code: 200};
    } catch(error: any) {
        console.log("error:", error);
        return {code: 500, error: error}
    }
}

export const setTeacherLocation = async (userId: string, homeLocation: PlaceType) => {
    try {
        let address = homeLocation.address_components;
        let geometry = homeLocation.geometry;
        if(!address || !geometry) return {};
        let country = address.filter(el => el.types.includes('country'))[0].long_name;
        let state = address.filter(el => el.types.includes('administrative_area_level_1'))[0].short_name;
        let suburb = address.filter(el => el.types.includes('locality'))[0].long_name;
        let postal_code = address.filter(el => el.types.includes('postal_code'))[0].long_name;
        let long_address = homeLocation.formatted_address || homeLocation.structured_formatting.secondary_text || homeLocation.description || 'No suitable address';

        const userNode = ref(getDatabase(), `users/${userId}`);
        await update(userNode, {
            homeCountry: country,
            homeState: state
        })
        const teacherHomeLocationNode = ref(getDatabase(), `teachers/${userId}/homeLocation`);
        await update(teacherHomeLocationNode, {
            address: long_address,
            country: country,
            homeState: state,
            lat: geometry.location.lat(),
            long: geometry.location.lng(),
            postCode: postal_code,
            suburb: suburb,
            state: 'submitted'
        })
        const teacherSetLocationNode = ref(getDatabase(), `teachers/${userId}/setLocation`);
        await update(teacherSetLocationNode, {
            lat: geometry.location.lat(),
            long: geometry.location.lng(),
            country: country,
            homeState: state,
        })
        return {code: 200}
    } catch (error) {
        return {code: 500, error: error}
    }
}

export const updateTeacherContact = async (userId: string, contact: string) => {
    try {
        const userNode = ref(getDatabase(), `users/${userId}`);
        await update(userNode, {
            contact: contact
        })
        const teacherNode = ref(getDatabase(), `teachers/${userId}/contact`);
        await update(teacherNode, {
            value: contact
        })
        return {code: 200}
    } catch (error) {
        return {code: 500, error: error}
    }
}

export const updateTeacherProfilePhoto = async (user: User, photoUrl: string) => {
    try {
        const userNode = ref(getDatabase(), `users/${user.uid}`);
        await update(userNode, {
            profilePictureURL: photoUrl
        });
        const teacherNode = ref(getDatabase(), `teachers/${user.uid}/profilePicture`);
        await update(teacherNode, {
            value: photoUrl,
            state: 'submitted'
        })

        await updateProfile(user, { photoURL: photoUrl});
        
        return {code: 200}
    } catch(error) {
        return {code: 500, error: error};
    }
}

export const uploadAccreditationDocument = async (userId: string, doc: AccreditationDocument) => {
    try {

        const teacherAccNode = ref(getDatabase(), `teachers/${userId}/accreditation/${doc.id}`);
        const teacherReqNode = ref(getDatabase(), `adminNotifications/teacherRequest/new/${v4()}`);

        await update(teacherAccNode, doc);

        const req:TeacherRequest = {
            state: 'submitted',
            teacherUid: userId,
            documentUid: doc.id,
            timeStamp: new Date().getTime()/1000,
            type: TeacherRequestType.ACC_DOC_UPDATE.valueOf(),
            url: doc.imageURL
        }

        await update(teacherReqNode, req);

        return {code: 200}
    } catch (error) {
        return {code: 500, error: error}
    }
}