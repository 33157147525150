import { Box, Stack } from "@mui/material";
import { updateProfile } from "firebase/auth";
import { useEffect, useState } from "react";
import AlertComponent from "../../../../components/AlertComponent";
import { auth } from "../../../../firebase/configs";
import { uploadFileToStorageWithProgress, updateUserPhoto } from "../../../../firebase/general-apis";
import { getUserStoragePath } from "../../../../firebase/helpers";
import pxToRem from "../../../../helpers/pxToRem";
import { getError } from "../../../../helpers/utils";
import PhotoUpload from "./PhotoUpload";
import styles from "../index.module.scss";

import sampleProfile from "../../../../assets/images/teacher-registration/sample-profile-photo.png";
import { RegModalWrapper } from "../ModalContent";
import { getIncompleteState } from "../../ReviewPage/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import FileUpload from "./FileUpload";
import { updateTeacherProfilePhoto } from "../../../../firebase/teacherApi";

const ProfilePhoto = () => {
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.user.userId);
    const [uploadModalOpen, setUploadModalOpen] = useState(true);
    const [photoUploadState, setPhotoUploadState] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string|undefined>();
    const reg = useSelector((state:RootState) => state.userReg.fields);
    const [photoUrl, setPhotoUrl] = useState<string|undefined>(reg.profilePicture?.value || undefined);

    const uploadImageToStorage = async (file:File) => {
        if(file && auth.currentUser){
            setPhotoUploadState(true);
            try{
                const storageRef = await uploadFileToStorageWithProgress({path: getUserStoragePath(auth.currentUser?.uid), file: file, onProgress: () => { }});
                if(storageRef.code == 200){
                    if(auth.currentUser){
                        setPhotoUrl(storageRef.data[1]);
                        await updateTeacherProfilePhoto(auth.currentUser, storageRef.data[1]);
                        setUploadModalOpen(false);
                    }
                    else{
                        setErrorMsg("Error: User not found.");
                    }
                }
                else{
                    setErrorMsg("Error: Please try again");
                }
            }
            catch(error){
                setErrorMsg(`${JSON.stringify(getError(error))}`);
            }
            setPhotoUploadState(false);
        }
    }

    const isAllComplete= () => {
        return photoUrl !== undefined && photoUrl !== ''
    }

    const handleUpdate = async () => {
        if(!auth.currentUser) {return;}
        let updateData: UserUpdate = {};
        if (photoUrl === '') {
            updateData = {
                teacher: {
                    path: '/profilePicture',
                    data: {
                        value: null,
                        state: 'incomplete'
                    }
                },
                user: {
                    path: '/',
                    data: {
                        profilePictureURL: null
                    }
                }
            }
            await updateProfile(auth.currentUser, { photoURL: ''});
        }
        dispatch(updateFields(updateData))
    };

    const fileDelete = () => {
        setPhotoUrl('');
    }

    const onUploadSuccess = async (url: string) => { setPhotoUrl(url); }
    const handleDelete = async () => { 
        setPhotoUrl(undefined); 
    }

    useEffect(() => {
        reg.profilePicture?.value?setPhotoUrl(reg.profilePicture?.value):setPhotoUrl(undefined);
    }, [reg.profilePicture?.value])

    return (
        <>
            <AlertComponent severity={"error"} message={errorMsg||""} visible={errorMsg!==undefined} handleConfirm={()=>setErrorMsg(undefined)}/>
            <RegModalWrapper title="Profile Photo" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    profilePicture: reg.profilePicture,
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Upload a profile photo</h5>
                        <p className={styles.p}>This image will be printed onto your Spark ID once your account is approved.</p>
                    </Stack>
                    <Stack className={styles.profileContainer}>
                        <Box className={styles['profilePhoto--left']}>
                            <PhotoUpload uploadState={photoUploadState} fileUrl={photoUrl} 
                                title="Upload New Picture" subTitle="Adjust the position of your photo by dragging the image" 
                                open={uploadModalOpen} close={()=>setUploadModalOpen(false)} 
                                upload={uploadImageToStorage} fileDelete={fileDelete}
                            />
                        </Box>
                        <Stack className={styles['profilePhoto--right']}>
                            <img src={sampleProfile}/>
                            <Stack>
                                <p className={styles.p}>TIPS</p>
                                <ol style={{color: styles.shades100}}>
                                    <li>The background must be neutral</li>
                                    <li>No filters</li>
                                    <li>Make sure you're looking directly at the camera</li>
                                    <li>Feel free to smile!</li>
                                </ol>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    )
}

export default ProfilePhoto;