export interface RouteStore {
  [routeName: string]: JSX.Element;
}

export enum TabNames {
  HOME = "home",
  TEACHERS = "teachers",
  SCHOOLS = "schools",
  ABOUT = "about",
  LOGIN = "login",
  REGISTER = "register",
  GUIDES = "guides",
  HELP_DESK = "help-desk",
  TERMS_OF_SERVICE = "terms-of-service",
  OLD_PROJECT_URL = "user",
  DASHBOARD = "dashboard",
  TEACHER_REFERAL_PROGRAM = "teacher-referral-program",
  BlogsHomePage = "blog",
  RefereeSurveyPage = "sparkreferee",
  SPARK_USER_BENEFITS = "spark-user-benefits",
  OnboardingTeacherOptOutPage = "communications-opt-out",
  ProfessionalLearningResourcePage = "pdresources",
  SHOP = "shop",
  CHECK_OUT = "check-out",
  STRIPT_CHECKOUT_ENDPOINT = "createCheckoutSession",
  CHECKOUT_SUCCESS = "checkout-success",
  SHOP_TERMS = "shop-policies",
  TEACHER_FAQ = "teacher-frequently-asked-questions",
  SCHOOL_FAQ = "school-frequently-asked-questions",
  TEACHER_APPLICATION = "employment-review",
  VERIFY_EMAIL='verify',
  REFEREE_SURVEY="referee",

  DOC_API="docs"
}

export enum DashboardTabNames {
  MY_ACCOUNT='my-account',
  REVIEW='review',
  SETTINGS='settings',
  HELP_DESK='help',
  MY_SCHOOL='my-school',
}

export const GUIDE_TYPES = {
  TEACHER_GUIDES: "teacher_guides",
  SCHOOL_GUIDES: "school_guides",
};

export const RESET_PASSWORD = "reset-password";

export enum REGISTER_TYPE {
  SCHOOL = "school",
  TEACHER = "teacher",
}
