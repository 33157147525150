import { InputLabel, Select, MenuItem, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserRegTextField } from "../../../../components/TextField";
import { updateUserInfo } from "../../../../firebase/general-apis";
import { getUserDocStoragePath } from "../../../../firebase/helpers";
import { getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import pxToRem from "../../../../helpers/pxToRem";
import useTextInput from "../../../../hooks/useTextInput";
import { RootState } from "../../../../redux/store";
import FileUpload from "./FileUpload";
import styles from "../index.module.scss";
import { RegModalWrapper } from "../ModalContent";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { WORKING_RIGHTS } from "./helper";
import { getIncompleteState } from "../../ReviewPage/helper";
import { DateValidationError } from "@mui/x-date-pickers/models";
import { CustomizedDatePicker } from "../../../../components/DatePicker";
import { TeacherRegFormControl } from "../../../../components/FormControl";
import { allFieldsDefined } from "../../../../helpers/utils";
dayjs.extend(customParseFormat);

// = ["Australian Citizen", "Permanent Resident", "Temporary Working Visa"];
const WorkingRights = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user);
    const userId = useSelector((state: RootState) => state.user.userId);
    const reg = useSelector((state:RootState) => state.userReg.fields);

    const [dateError, setDateError] = useState<DateValidationError | null>(null);

    const [selected, setSelected] = useState<string|undefined>(reg.workingRights?.value);
    const reference = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Document reference") }, defaultValue: reg.workingRights?.reference || ""});   
    const [expiryDate, setExpiryDate] = useState<Dayjs|null>(reg.workingRights?.expiry?dayjs(reg.workingRights?.expiry, "DD-MM-YYYY"):null); 

    const errorMessage = useMemo(() => {
        switch(dateError) {
            case 'minDate': {
                return 'Please select a date after today'
            }
            default: return undefined
        }
    },[dateError])

    const onUploadSuccess = async (url: string, mimeType: string) => {
        if(!userId) {return}
        const allComplete = allFieldsDefined(reg.workingRights, ['url', 'type']) && url !== '';
        await updateUserInfo(userId, {
            teacher: { path: "/workingRights", data:{ 
                url: url,
                type: mimeType,
                state: allComplete?'submitted':'incomplete'
            }}
        })
    }

    const handleDelete = async () => {
        if(!userId) {return}
        await updateUserInfo(userId, {
            teacher: { path: "/workingRights", data:{ url: null, type: null, state: 'incomplete' } }
        })
    }

    const isAllComplete = ():boolean => {
        return (selected === 'unrestrictedCitizen') || (selected !== undefined && !dateError && expiryDate?.isValid() && !reference.hasError && reference.value.trim() !== '' && reg.workingRights?.url !== undefined && reg.workingRights?.url !== '') || false
    }
    const isSomeComplete = ():boolean => {
        return selected !== undefined || (!dateError && expiryDate?.isValid()) || reference.value !== "" || reg.workingRights?.url !== undefined
    }


    const handleUpdate = () => {
        let formattedDate = '';
        if(expiryDate && !dateError && expiryDate.isValid()) { formattedDate = expiryDate.format("DD-MM-YYYY"); }

        let updateData: UserUpdate = {};

        if(reg.workingRights) {
            switch(selected) {
                case "unrestrictedCitizen":
                    updateData = {
                        teacher: {
                            path:"/",
                            data: { workingRights: {value: selected, state: "submitted" }}
                        }
                    }
                    break;
                default:
                    updateData = {
                        teacher: {
                            path: "/workingRights",
                            data: {
                                ...(isAllComplete() ? {state: 'submitted'}: isSomeComplete() ? {state: 'incomplete'} : null),
                                ...(reg.workingRights.value ?
                                        reg.workingRights.value !== selected ? {value: selected?selected:null} : null
                                    :
                                        {value: selected?selected:null}
                                ),
                                ...(reg.workingRights.reference ? 
                                        reg.workingRights.reference !== reference.value ? {reference: !reference.hasError?reference.value:null} : null
                                    :
                                        {reference: !reference.hasError && reference.value.trim() !== "" ?reference.value:null}
                                ),
                                ...(reg.workingRights.expiry ? 
                                        reg.workingRights.expiry !== formattedDate ? {expiry: !dateError && formattedDate?formattedDate:null} : null
                                    :
                                        {expiry: !dateError && formattedDate?formattedDate:null}
                                ),
                            },
                        }
                    }
            }
        }

        dispatch(updateFields(updateData));
    }

    useEffect(() => {
        reg.workingRights?.expiry?setExpiryDate(dayjs(reg.workingRights.expiry, "DD-MM-YYYY")):setExpiryDate(null);
    }, [reg.workingRights?.expiry])

    useEffect(() => {
        reg.workingRights?.reference?reference.setValue(reg.workingRights.reference):reference.reset();
    }, [reg.workingRights?.reference])

    useEffect(() => {
        reg.workingRights?.value?setSelected(reg.workingRights.value):setSelected(undefined);
    }, [reg.workingRights?.value])

    return (
        <>
            <RegModalWrapper title="Working Rights" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    workingRights: reg.workingRights
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Working Rights</h5>
                        <p className={styles.p}>Select your Australian working rights</p>
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <TeacherRegFormControl variant="standard" >
                            <InputLabel>Working Rights</InputLabel>
                            <Select value={selected} onChange={(e)=>setSelected(e.target.value)}>
                                {
                                    WORKING_RIGHTS.sort((a,b) => a.label.localeCompare(b.label)).map((el, i) => (
                                        <MenuItem key={i} value={el.value}>{el.label}</MenuItem>
                                    ))
                                }
                            </Select>
                        </TeacherRegFormControl>
                        {
                            selected !== undefined && selected !== WORKING_RIGHTS.filter(el => el.value === "unrestrictedCitizen")[0].value && 
                            <>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    {/* <DemoContainer components={['DatePicker']}> */}
                                        <CustomizedDatePicker value={expiryDate} onChange={(e)=>setExpiryDate(e)} minDate={dayjs()} label="Expiry Date" format="DD/MM/YYYY"
                                            onError={(newError) => setDateError(newError)}
                                            slotProps={{
                                                textField: {
                                                    helperText: errorMessage,
                                                }
                                            }} 
                                        />
                                    {/* </DemoContainer> */}
                                </LocalizationProvider>
                                <UserRegTextField 
                                    value={reference.value}
                                    onChange={(e)=>reference.setValue(e.target.value)}
                                    error={reference.hasTouched && reference.hasError} helperText={reference.getErrorMessage()}
                                    label="Document Reference"
                                />
                                {userId!==null && <FileUpload file={reg.workingRights?.url} path={getUserDocStoragePath(userId, `workingRights${moment().format("DD-MM-YYYY-HH:mm:ss")}`)} label="Upload Supporting Documents" onSuccess={onUploadSuccess} accept={'image/png, image/jpeg, application/pdf'} handleDelete={handleDelete}
                                    icon={<svg width="76" height="68" viewBox="0 0 76 68" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M52.7368 29.7893C60.7632 30.5434 67.9087 32.2074 73.8708 35.5851C81.708 40.0251 62.6184 41.7167 61.5716 48.2251C60.4364 55.2836 79.8083 64.0999 68.9023 66.4782C59.0531 68.6261 49.7556 64.3839 39.9082 62.2328C27.3096 59.4807 5.51688 59.7818 1.02499 52.0627C-3.60292 44.1099 13.4353 39.4355 24.2924 34.2358C32.9213 30.1033 41.9112 28.7722 52.7368 29.7893Z" fill="#F5F5F5"/> <path d="M28.1069 49.8894H47.894C51.8848 49.8894 53.9452 47.7919 53.9452 43.7825V26.9282C53.9452 24.3295 53.6111 23.1416 51.9962 21.4895L42.2883 11.6331C40.7105 10.0368 39.4112 9.66553 37.0723 9.66553H28.1069C24.1346 9.66553 22.0557 11.763 22.0557 15.791V43.7825C22.0557 47.7919 24.1346 49.8894 28.1069 49.8894ZM28.4224 46.177C26.6405 46.177 25.7495 45.2489 25.7495 43.5412V16.0137C25.7495 14.3246 26.6405 13.3779 28.441 13.3779H36.3113V23.4757C36.3113 26.1672 37.6292 27.4665 40.3021 27.4665H50.2514V43.5412C50.2514 45.2489 49.3604 46.177 47.5599 46.177H28.4224ZM40.6548 24.2182C39.8752 24.2182 39.5411 23.884 39.5411 23.123V13.8605L49.7502 24.2182H40.6548ZM44.5343 32.3298H31.1325C30.4271 32.3298 29.926 32.8309 29.926 33.4806C29.926 34.1488 30.4271 34.6686 31.1325 34.6686H44.5343C45.2025 34.6686 45.7037 34.1488 45.7037 33.4806C45.7037 32.8309 45.2025 32.3298 44.5343 32.3298ZM44.5343 38.5295H31.1325C30.4271 38.5295 29.926 39.0492 29.926 39.7174C29.926 40.3671 30.4271 40.8683 31.1325 40.8683H44.5343C45.2025 40.8683 45.7037 40.3671 45.7037 39.7174C45.7037 39.0492 45.2025 38.5295 44.5343 38.5295Z" fill="#FCB218"/> </svg> }
                                />}
                            </>

                        }
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default WorkingRights;