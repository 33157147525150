import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/store";
import styles from "./index.module.scss";

interface IFooterButton {
  link: string;
  text: string;
  openInNewTab: boolean;
}

const FooterButton = ({ link, text, openInNewTab }: IFooterButton) => {
  const screenType = useSelector((state: RootState) => state.ui.screenType);
  const navigate = useNavigate();

  return (
    <Button
      className={`${styles.footerButton} ${
        screenType === "PC"
          ? "title_medium bold_weight"
          : screenType === "TABLET"
          ? "label_medium medium_weight"
          : `${styles.mobile_button_size} medium_weight`
      }`}
      onClick={() => {
        if (openInNewTab) {
          window.open(link);
        } else {
          navigate(link);
        }
      }}
    >
      {text}
    </Button>
  );
};

export default FooterButton;
