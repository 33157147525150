import { Stack } from "@mui/material";
import { RegModalWrapper } from "../ModalContent";

const ErrorState = () => {

    return (
        <RegModalWrapper title={"Teaching Documentation"} handleUpdate={()=>{}}>
            <Stack>
                We currently do not operate in this state
            </Stack>
        </RegModalWrapper>
    )
}

export default ErrorState;