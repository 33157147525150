import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";

import { connectDatabaseEmulator, getDatabase } from "firebase/database";
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from "firebase/storage";
 
export const FIREBASE_CONFIG = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATA_BASE_URL}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};


export const app = initializeApp(FIREBASE_CONFIG);
export const auth = getAuth(app);

// Configurations for connecting Firebase Emulators
// const functions = getFunctions(app);

// if(process.env.NODE_ENV === 'development') {
//   console.log("Connecting emulators...");
//   connectDatabaseEmulator(getDatabase(), '127.0.0.1', 9000);
//   connectAuthEmulator(auth, 'http://127.0.0.1:9099');
//   connectStorageEmulator(getStorage(), '127.0.0.1', 9199);
//   connectFunctionsEmulator(functions, '127.0.0.1', 5001);
// }