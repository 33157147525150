import { DashboardTabNames, TabNames } from "./type";
import { GUIDE_TYPES } from "./type";

const titles_routers = {
  [`/`]: "Home - Spark Relief Teachers",
  [`/${TabNames.TEACHERS}`]: "Teachers - Spark Relief Teachers",
  [`/${TabNames.TEACHERS}/vic`]: "Teachers - VIC",
  [`/${TabNames.TEACHERS}/nsw`]: "Teachers - NSW",
  [`/${TabNames.SCHOOLS}`]: "Schools - Spark Relief Teachers",
  [`/${TabNames.SCHOOLS}/vic`]: "Schools - VIC",
  [`/${TabNames.SCHOOLS}/nsw`]: "Schools - NSW",
  [`/${TabNames.ABOUT}`]: "About - Spark Relief Teachers",
  [`/${TabNames.HELP_DESK}`]: "Help Desk - Spark Relief Teachers",
  [`/${TabNames.TERMS_OF_SERVICE}`]: "Terms of Registration - Spark Relief Teachers",
  [`/${TabNames.TEACHER_REFERAL_PROGRAM}`]:
    "Teacher Referral Program - Spark Relief Teachers",
  [`/${TabNames.BlogsHomePage}`]: "Blog - Spark Relief Teachers",
  [`/${TabNames.BlogsHomePage}/renewing-your-vit`]:
    "Renewing your VIT Registration",
  [`/${TabNames.BlogsHomePage}/free-resources`]:
    "The Best (Free) Casual Relief Teacher Resources - Spark Relief Teachers",
  [`/${TabNames.BlogsHomePage}/teacherburnout`]:
    "How to deal with teacher burnout as a Casual Relief Teacher - Spark Relief Teachers",
  [`/${TabNames.BlogsHomePage}/pay-increase`]:
    "Maximum Pay Rate for CRTs - Spark Relief Teachers Pay Increase - Spark Relief Teachers",
  [`/${TabNames.BlogsHomePage}/relief-teacher-bag-essentials`]:
    "What to Put in your Relief Teacher Bag - Spark Relief Teachers",
  [`/${TabNames.BlogsHomePage}/permission-to-teach`]:
    "Permission to Teach for VIC Final Year Education Students - Spark Relief Teachers",
  [`/${TabNames.OLD_PROJECT_URL}/login`]: "Login - Spark Relief Teachers",
  [`/${TabNames.OLD_PROJECT_URL}/register`]: "Register - Spark Relief Teachers",
  [`/${TabNames.GUIDES}/${GUIDE_TYPES.SCHOOL_GUIDES}`]:
    "School Guides - Spark Relief Teachers",
  [`/${TabNames.GUIDES}/${GUIDE_TYPES.TEACHER_GUIDES}`]:
    "Teacher Guides - Spark Relief Teachers",
  [`/${TabNames.OLD_PROJECT_URL}/${TabNames.DASHBOARD}`]:
    "Dashboard - Spark Relief Teachers",
  [`/${TabNames.OLD_PROJECT_URL}/myaccount`]:
    "My Account - Spark Relief Teachers",
  [`/${TabNames.RefereeSurveyPage}`]:
    "Spark Relief Teachers Candidate Referee Survey",
  [`/${TabNames.SPARK_USER_BENEFITS}`]: "Spark User / VIT Benefits",
  [`/${TabNames.ProfessionalLearningResourcePage}`]:
    "Professional Development Resources",
  [`/${TabNames.SHOP}`]: "Spark Shop – Spark Relief Teachers",

  [`/${TabNames.DASHBOARD}`]: "Dashboard - Spark Relief Teachers",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.REVIEW}`]: "Review - Spark Relief Teachers",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.MY_ACCOUNT}`]: "My Account - Spark Relief Teachers",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.SETTINGS}`]: "Settings - Spark Relief Teachers",
  [`/${TabNames.DASHBOARD}/${DashboardTabNames.HELP_DESK}`]: "Help Desk - Spark Relief Teachers",
};

export default titles_routers;
