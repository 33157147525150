

import { InputLabel, Select, MenuItem, Stack } from "@mui/material";
import moment from "moment";
import { useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isObjectEmpty, updateUserInfo } from "../../../../firebase/general-apis";
import { getUserDocStoragePath } from "../../../../firebase/helpers";
import pxToRem from "../../../../helpers/pxToRem";
import { RootState } from "../../../../redux/store";
import styles from "../index.module.scss";
import { RegModalWrapper } from "../ModalContent";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getIncompleteState } from "../../ReviewPage/helper";
import { TeacherRegFormControl } from "../../../../components/FormControl";
import { v4 as uuidv4 } from "uuid";
import MultipleFileUpload from "./MultipleFileUpload";
import { NSWAccreditationLevel } from "../../../../firebase/types-teacher";
dayjs.extend(customParseFormat);

// = ["Australian Citizen", "Permanent Resident", "Temporary Working Visa"];
const AccreditationLevel = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.user);
    const userId = useSelector((state: RootState) => state.user.userId);
    const reg = useSelector((state: RootState) => state.userReg.fields);

    const [selected, setSelected] = useState<NSWAccreditationLevel | undefined>(reg.service?.userLevelNomination);
    // const reference = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, "Document reference") }, defaultValue: reg.workingRights?.reference || ""});   
    // const [expiryDate, setExpiryDate] = useState<Dayjs|null>(reg.workingRights?.expiry?dayjs(reg.workingRights?.expiry, "DD-MM-YYYY"):null); 
    const [docUrl, setDocUrl] = useState<{ id: string, path: string, file: any, fileType?: string }[]>(Object.entries(reg.service?.urls || {}).map(([id, el]) => { return { id: id, path: getUserDocStoragePath(userId || '', `serviceStatement-${id}`), file: typeof el === 'string' ? el : el.value, fileType: typeof el === 'string' ? undefined : el.type } }));
    const newId = useRef(uuidv4());

    const handleUpdateFile = async (files: { id: string, path: string, file: any, mimeType?: string }[]) => {
        if (!userId) { return; }
        setDocUrl(files);

        let updatedFiles: any = {};
        for (let f of files) {
            updatedFiles[f.id] = {
                value: f.file,
                type: f.mimeType || null
            }
        }

        if(isObjectEmpty(updatedFiles)) {
            await updateUserInfo(userId, {
                teacher: {
                    path: `/`, data: {
                        service: null
                    }
                }
            })
        } else {
            await updateUserInfo(userId, {
                teacher: {
                    path: `/service/${reg.service?.serviceId || newId.current}`, data: {
                        userLevelNomination: selected,
                        userLocation: isSomeComplete() ? reg.setLocation?.homeState || 'NSW' : null,
                        urls: updatedFiles,
                        timeUploaded: moment.tz(moment(), "Australia/Melbourne").unix(),
                        uploadedBy: userId,
                    }
                }
            }, true);
        }
    }

    const isAllComplete = (): boolean => {
        return selected !== undefined && ([NSWAccreditationLevel.BAND1, NSWAccreditationLevel.UNSURE].includes(selected) || docUrl.length > 0);
    }
    const isSomeComplete = (): boolean => {
        return selected !== undefined
    }


    const handleUpdate = () => {
        let updateData: UserUpdate = {};

        if (selected === NSWAccreditationLevel.BAND1) {
            updateData = {
                teacher: {
                    path: `/service/${reg.service?.serviceId || newId.current}`,
                    data: {
                        userLevelNomination: selected,
                        userLocation: isSomeComplete() ? reg.homeAddress?.homeState || 'NSW' : null,
                        timeUploaded: moment.tz(moment(), "Australia/Melbourne").unix(),
                        uploadedBy: userId,
                    }
                }
            }
        } else if (docUrl.length > 0) {
            updateData = {
                teacher: {
                    path: `/service/${reg.service?.serviceId || newId.current}`,
                    data: {
                        userLevelNomination: selected,
                        userLocation: isSomeComplete() ? reg.setLocation?.homeState || 'NSW' : null,
                        timeUploaded: moment.tz(moment(), "Australia/Melbourne").unix(),
                        uploadedBy: userId
                    }
                }
            }
        } else {
            updateData = {
                teacher: {
                    path: `/`,
                    data: {
                        service: null
                    }
                }
            }
        }

        dispatch(updateFields(updateData));
    }

    const renderText = useMemo(() => {
        switch (selected) {
            case NSWAccreditationLevel.BAND1: return <>I have Provisional teaching accreditation from NESA.</>
            case NSWAccreditationLevel.BAND2: return <>I am an accredited teacher who has:<br />a{')'} Proficient Accreditation, and <br />b{')'} Completed 2 years {'('}406 days{')'} of full-time equivalent {'('}FTE{')'} experience.</>
            case NSWAccreditationLevel.BAND3: return <>I am an accredited teacher who has:<br />a{')'} Proficient Accreditation, and <br />b{')'} Completed more than 2 years {'('}406 days{')'} of full-time equivalent {'('}FTE{')'} experience at the Proficient level.</>
            case NSWAccreditationLevel.UNSURE: return <>I am unsure which band I belong to and would like some guidance on the requirements and qualifications needed to determine my appropriate level as a teacher.</>
            default: return <></>
        }
    }, [selected])

    const getLabel = (level: NSWAccreditationLevel) => {
        switch (level) {
            case NSWAccreditationLevel.BAND1: return "Band 1 (Provisional Teacher)"
            case NSWAccreditationLevel.BAND2: return "Band 2 (Proficient Teacher - more than 2 years FTE)"
            case NSWAccreditationLevel.BAND3: return "Band 3 (Proficient Teacher - more than 2 years FTE at the proficient level)"
            case NSWAccreditationLevel.UNSURE: return "I'm Unsure"
            default: return <></>
        }
    }

    return (
        <>
            <RegModalWrapper title="Accreditation Level" handleUpdate={handleUpdate}
                state={getIncompleteState({
                    workingRights: reg.workingRights
                })} isAllComplete={isAllComplete()}
            >
                <Stack gap={pxToRem(40)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >Accreditation Level</h5>
                        <p className={styles.p}>To ensure you are paid correctly, nominate the band based on your years of experience:</p>
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <TeacherRegFormControl variant="standard" >
                            <InputLabel>Casual Teacher Band</InputLabel>
                            <Select value={selected} onChange={(e) => setSelected(e.target.value as NSWAccreditationLevel)}
                                MenuProps={{
                                    PaperProps: { style: { overflowX: 'scroll' } }
                                }}
                            >
                                {
                                    Object.values(NSWAccreditationLevel).sort((a, b) => a.localeCompare(b)).map((el, i) => (
                                        <MenuItem key={i} value={el}>{getLabel(el)}</MenuItem>
                                    ))
                                }
                            </Select>
                        </TeacherRegFormControl>
                        <Stack className={styles.accreditationLevelBanner} sx={{ opacity: selected !== undefined ? 1 : 0, transition: 'all 300ms' }}>
                            <small> {renderText} </small>
                        </Stack>
                        {
                            selected !== undefined &&
                            <>
                                {userId &&
                                    <MultipleFileUpload
                                        file={docUrl} path={getUserDocStoragePath(userId, `serviceStatement-`)}
                                        label={`Upload statement of service ${[NSWAccreditationLevel.BAND1, NSWAccreditationLevel.UNSURE].includes(selected) ? '(Optional)' : '(Required)'}`} setFile={handleUpdateFile}
                                        accept={'image/png, image/jpeg, application/pdf'}
                                        icon={<svg width="82" height="72" viewBox="0 0 82 72" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M56.9493 31.4431C65.6361 32.2592 73.3694 34.0602 79.8221 37.7157C88.3042 42.521 67.6439 44.3518 66.511 51.3957C65.2824 59.035 86.2482 68.5767 74.4448 71.1507C63.7852 73.4753 53.7227 68.8841 43.0651 66.556C29.4299 63.5774 5.84414 63.9033 0.982672 55.5491C-4.02601 46.9419 14.414 41.883 26.1645 36.2555C35.5034 31.783 45.233 30.3423 56.9493 31.4431Z" fill="#F5F5F5" /> <path d="M30.2928 53.1971H51.7079C56.0271 53.1971 58.257 50.927 58.257 46.5877V28.3466C58.257 25.5341 57.8954 24.2484 56.1477 22.4604L45.641 11.793C43.9334 10.0654 42.5271 9.66357 39.9959 9.66357H30.2928C25.9937 9.66357 23.7437 11.9337 23.7437 16.293V46.5877C23.7437 50.927 25.9937 53.1971 30.2928 53.1971ZM30.6343 49.1792C28.7057 49.1792 27.7414 48.1747 27.7414 46.3265V16.5341C27.7414 14.706 28.7057 13.6814 30.6544 13.6814H39.1722V24.61C39.1722 27.5229 40.5986 28.9292 43.4914 28.9292H54.2593V46.3265C54.2593 48.1747 53.295 49.1792 51.3463 49.1792H30.6343ZM43.8731 25.4136C43.0294 25.4136 42.6678 25.052 42.6678 24.2283V14.2038L53.7169 25.4136H43.8731ZM48.0718 34.1926H33.5673C32.8039 34.1926 32.2615 34.735 32.2615 35.4381C32.2615 36.1613 32.8039 36.7238 33.5673 36.7238H48.0718C48.795 36.7238 49.3374 36.1613 49.3374 35.4381C49.3374 34.735 48.795 34.1926 48.0718 34.1926ZM48.0718 40.9024H33.5673C32.8039 40.9024 32.2615 41.4649 32.2615 42.1881C32.2615 42.8913 32.8039 43.4337 33.5673 43.4337H48.0718C48.795 43.4337 49.3374 42.8913 49.3374 42.1881C49.3374 41.4649 48.795 40.9024 48.0718 40.9024Z" fill="#FCB218" /> </svg>}
                                    />
                                }
                            </>

                        }
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};

export default AccreditationLevel;