import { Stack } from "@mui/material";
import { RegModalWrapper } from "../ModalContent";
import pxToRem from "../../../../helpers/pxToRem";
import styles from "../index.module.scss";
import { UserRegTextField } from "../../../../components/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import useTextInput from "../../../../hooks/useTextInput";
import { getErrorMsgForTextInput } from "../../../../helpers/inputValidators";
import FileUpload from "./FileUpload";
import { getUserDocStoragePath } from "../../../../firebase/helpers";
import moment from "moment";
import { updateFields, UserUpdate } from "../../../../redux/userRegistrationSlice";
import { updateUserInfo } from "../../../../firebase/general-apis";
import { LightTooltip } from "../../../../components/Tooltip";
import { getIncompleteState } from "../../ReviewPage/helper";
import { DateValidationError } from "@mui/x-date-pickers/models";
import { CustomizedDatePicker } from "../../../../components/DatePicker";
import { AccreditationDocument, AccreditationDocumentDetails } from "../../../../redux/types";
import { FieldState } from "../../../../firebase/types-teacher";
import { allFieldsDefined } from "../../../../helpers/utils";
import { uploadAccreditationDocument } from "../../../../firebase/teacherApi";
import { v4 as uuidv4 } from "uuid";


interface ITeachingDocumentation {
    title: string;
    details: AccreditationDocumentDetails;
}
const TeachingDocumentation = ({title, details}: ITeachingDocumentation) => {
    const dispatch = useDispatch();
    const reg = useSelector((state:RootState) => state.userReg.fields);
    const userId = useSelector((state:RootState) => state.user.userId);

    const expired = useMemo(() => {
        return reg.accreditation?.[`${details.type}`]?.expiryDate?
            moment(reg.accreditation?.[`${details.type}`].expiryDate, "DD-MM-YYYY").isBefore(moment())
        : false
    }, [reg.accreditation?.[`${details.type}`]])

    const [expiryDate, setExpiryDate] = useState<Dayjs|null>(expired? null: reg.accreditation?.[`${details.type}`]?.expiryDate?dayjs(reg.accreditation?.[`${details.type}`]?.expiryDate, "DD-MM-YYYY"): null);
    const reference = useTextInput({ inputValidator: (input: string) => { return getErrorMsgForTextInput(input, `${details.short_title} reference`) }, defaultValue: expired? "" : reg.accreditation?.[`${details.type}`]?.reference || ""});   
    const [docUrl, setDocUrl] = useState(expired? undefined: reg.accreditation?.[`${details.type}`]?.imageURL);
    const [docMimeType, setDocMimeType] = useState(expired? undefined: reg.accreditation?.[`${details.type}`]?.type);

    const docId = useRef(uuidv4());

    const [dateError, setDateError] = useState<DateValidationError | null>(null);
    const errorMessage = useMemo(() => {
        switch(dateError) {
            case 'minDate': {
                return 'Please select a date after today'
            }
        }
    },[dateError]);

    

    const onUploadSuccess = async (url: string, mimeType: string) => { 
        if(userId) {
            setDocUrl(url); 
            setDocMimeType(mimeType);
            // Don't write to DB if user is making a request to update the document
            if(expired) {
                return;
            }
            const allComplete = allFieldsDefined(reg.accreditation?.[`${details.type}`], ['id','accrediationType','dateSubmitted','imageURL', 'type', 'state']) && url !== '';
            let updateData = {
                teacher: {
                    path: `/accreditation/${reg.accreditation?.[`${details.type}`]?.id || docId.current}`,
                    data: {
                        accreditationType: details.type,
                        imageURL: url,
                        type: mimeType,
                        // dateSubmitted: new Date().getTime()/1000,
                        state: allComplete?'submitted':'incomplete'
                    }
                }
            }
            await updateUserInfo(userId, updateData);
        }
    }
    const handleDelete = async () => { 
        if(!userId) {return}
        setDocUrl(undefined); 
        setDocMimeType(undefined);
        if(expired) {
            return;
        }
        await updateUserInfo(userId, {
            teacher: { 
                path: `/accreditation/${reg.accreditation?.[`${details.type}`]?.id || docId.current}`,
                data: {
                    imageURL: null,
                    type: null,
                    dateSubmitted: null,
                    state: 'incomplete'
                }
            }
        });
    }
    
    const isAllComplete = () => {
        return !dateError && expiryDate?.isValid() && reference.value.trim() !== "" && !reference.hasError && docUrl!==undefined && docUrl !== ''
    }
    const isSomeComplete = () => {
        return Boolean(!dateError && expiryDate?.isValid()) || Boolean(reference.value) || Boolean(docUrl)
    }

    const handleUpdate = () => {
        let updateData: UserUpdate = {};
        let formattedExpiry = '';
        if(expiryDate && !dateError && expiryDate.isValid()) { formattedExpiry = expiryDate.format("DD-MM-YYYY"); }

        if(reg.accreditation?.[`${details.type}`] && reg.accreditation[`${details.type}`].id) {
            // Update data
            updateData = {
                teacher: {
                    path: `/accreditation/${reg.accreditation[`${details.type}`].id}`,
                    data: {
                        ...(reg.accreditation[`${details.type}`].expiryDate ? 
                                reg.accreditation[`${details.type}`].expiryDate !== formattedExpiry ? {expiryDate: !dateError && formattedExpiry?formattedExpiry:null} : null
                            :
                                {expiryDate: !dateError && formattedExpiry?formattedExpiry:null}
                        ),
                    
                        ...(reg.accreditation[`${details.type}`].imageURL ?
                                reg.accreditation[`${details.type}`].imageURL !== docUrl ? {imageURL: docUrl?docUrl:null} : null
                            :
                                {imageURL: docUrl?docUrl:null}
                        ),
                        ...(reg.accreditation[`${details.type}`].reference ? 
                                reg.accreditation[`${details.type}`].reference !== reference.value ? {reference: !reference.hasError && reference.value !== "" ?reference.value:null} : null
                            :
                                {reference: !reference.hasError && reference.value !== "" ?reference.value:null}
                        ),
                        ...(reg.accreditation[`${details.type}`].accreditationType ? null : {accreditationType: `${details.type}`}),
                        ...(isAllComplete() ? {state: 'submitted', dateSubmitted: moment().format("DD-MM-YYYY")} : isSomeComplete() ? {state: 'incomplete'}: null)
                    }
                }
            }
        } else {
            // Create new document
            if(isSomeComplete()) {
                console.log(reference.value, reference.value !== "");
                updateData = {
                    teacher: {
                        path:`/accreditation`,
                        data: {
                            [`${docId.current}`]: {
                                accreditationType: `${details.type}`,
                                expiryDate: !dateError && formattedExpiry?formattedExpiry: null,
                                dateSubmitted: moment().format("DD-MM-YYYY"),
                                imageURL: docUrl?docUrl:null,
                                reference: !reference.hasError && reference.value !== "" ?reference.value:null,
                                ...(isAllComplete() ? {state: 'submitted'} : isSomeComplete() ? {state: 'incomplete'}: null)
                            }
                        }
                    }
                }
            }
        }

        dispatch(updateFields(updateData));
    }

    const handleUpdateRequest = async () => {
        if(!userId) { return; }
        let formattedExpiry = '';
        if(expiryDate && !dateError && expiryDate.isValid()) { formattedExpiry = expiryDate.format("DD-MM-YYYY"); }
        const doc:AccreditationDocument = {
            id: uuidv4(),
            accreditationType: details.type,
            expiryDate: formattedExpiry,
            dateSubmitted: moment().format("DD-MM-YYYY"),
            imageURL: docUrl,
            reference: reference.value,
            type: docMimeType,
            state: 'submitted',
        } 
        const res = await uploadAccreditationDocument(userId, doc);
        if(res.code !== 200) {
            console.log("There was an error creating a new request!");
            return;
        } else {
            console.log("Successfully created teacher request!");
        }
    }
    
    useEffect(() => {
        if(expired) {return;}
        reg.accreditation?.[`${details.type}`] && reg.accreditation[`${details.type}`].expiryDate ? setExpiryDate(dayjs(reg.accreditation[`${details.type}`].expiryDate, "DD-MM-YYYY")):setExpiryDate(null);
    }, [reg.accreditation?.[`${details.type}`]?.expiryDate]);

    useEffect(() => {
        if(expired) {return;}
        reg.accreditation?.[`${details.type}`] && reg.accreditation[`${details.type}`].reference ? reference.setValue(reg.accreditation[`${details.type}`].reference || ''):reference.reset();
    }, [reg.accreditation?.[`${details.type}`]?.reference])

    useEffect(() => {
        if(expired) {return;}
        reg.accreditation?.[`${details.type}`] && reg.accreditation?.[`${details.type}`].imageURL ? setDocUrl(reg.accreditation?.[`${details.type}`].imageURL):setDocUrl(undefined);
    }, [reg.accreditation?.[`${details.type}`]?.imageURL])

    return (
        <>
            <RegModalWrapper title={title} handleUpdate={handleUpdate}
                state={reg.accreditation?.[`${details.type}`] && getIncompleteState({
                    accreditation: {[`${details.type}`]: reg.accreditation?.[`${details.type}`]}
                })} isAllComplete={isAllComplete()} 
                expired={expired}
                handleUpdateRequest={handleUpdateRequest}
            >
                <Stack gap={pxToRem(24)} className={styles.modalContainer}>
                    <Stack className={styles.titleContainer}>
                        <h5 className={styles.h5} >{details.short_title}</h5>
                        {
                            !reg.accreditation?.[`${details.type}`]?.state || reg.accreditation?.[`${details.type}`]?.state === FieldState.INCOMPLETE ?
                            <Stack className={styles.infoBox}>
                                <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8"/> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white"/> </svg>
                                <small>{details.details_description}</small>
                            </Stack>
                            :
                            <Stack direction='row' alignItems={'center'} gap={pxToRem(12)}>
                                <LightTooltip placement="bottom-start" title={ <p className={styles.p}>{details.details_description}</p> }>
                                    <svg className={styles.vitInfoIcon} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10" fill="#C465A8"/> <path d="M8.996 15V8.652H10.7V15H8.996ZM9.836 7.344C9.508 7.344 9.252 7.264 9.068 7.104C8.884 6.944 8.792 6.716 8.792 6.42C8.792 6.148 8.884 5.928 9.068 5.76C9.26 5.584 9.516 5.496 9.836 5.496C10.164 5.496 10.42 5.58 10.604 5.748C10.788 5.908 10.88 6.132 10.88 6.42C10.88 6.7 10.784 6.924 10.592 7.092C10.408 7.26 10.156 7.344 9.836 7.344Z" fill="white"/> </svg>
                                </LightTooltip>
                                <p className={styles.p}>Why we need this information</p>
                            </Stack>
                        }
                    </Stack>
                    <Stack gap={pxToRem(24)}>
                        <Stack>
                            <UserRegTextField variant='standard' value={details.short_title} label={'Type'} focused={false} onChange={()=>{}}/>
                            <a href={details.website_link} target="_blank" style={{textDecoration:'none', width: 'fit-content'}}><small style={{color:styles.primaryBlue500}}>View Website</small></a>
                        </Stack>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <CustomizedDatePicker value={expiryDate} onChange={(e)=>setExpiryDate(e)} minDate={dayjs()} label="Expiry" format="DD/MM/YYYY"
                                onError={(newError) => setDateError(newError)}
                                slotProps={{
                                    textField: {
                                        helperText: errorMessage
                                    }
                                }}
                                sx={{ flexGrow:1, }}
                            />
                        </LocalizationProvider>
                        <UserRegTextField
                            value={reference.value}
                            onChange={(e)=>reference.setValue(e.target.value)}
                            error={reference.hasTouched && reference.hasError} helperText={reference.getErrorMessage()}
                            label="Reference"
                        />
                        {
                            userId!==null && 
                            <FileUpload file={docUrl} path={getUserDocStoragePath(userId, `${details.type}-${moment().format("DD-MM-YYYY-HH:mm:ss")}`)} label={`Upload Document`} onSuccess={onUploadSuccess} accept={'image/png, image/jpeg, application/pdf'} handleDelete={handleDelete}
                                icon={<svg width="76" height="68" viewBox="0 0 76 68" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M52.7368 29.7893C60.7632 30.5434 67.9087 32.2074 73.8708 35.5851C81.708 40.0251 62.6184 41.7167 61.5716 48.2251C60.4364 55.2836 79.8083 64.0999 68.9023 66.4782C59.0531 68.6261 49.7556 64.3839 39.9082 62.2328C27.3096 59.4807 5.51688 59.7818 1.02499 52.0627C-3.60292 44.1099 13.4353 39.4355 24.2924 34.2358C32.9213 30.1033 41.9112 28.7722 52.7368 29.7893Z" fill="#F5F5F5"/> <path d="M28.1069 49.8894H47.894C51.8848 49.8894 53.9452 47.7919 53.9452 43.7825V26.9282C53.9452 24.3295 53.6111 23.1416 51.9962 21.4895L42.2883 11.6331C40.7105 10.0368 39.4112 9.66553 37.0723 9.66553H28.1069C24.1346 9.66553 22.0557 11.763 22.0557 15.791V43.7825C22.0557 47.7919 24.1346 49.8894 28.1069 49.8894ZM28.4224 46.177C26.6405 46.177 25.7495 45.2489 25.7495 43.5412V16.0137C25.7495 14.3246 26.6405 13.3779 28.441 13.3779H36.3113V23.4757C36.3113 26.1672 37.6292 27.4665 40.3021 27.4665H50.2514V43.5412C50.2514 45.2489 49.3604 46.177 47.5599 46.177H28.4224ZM40.6548 24.2182C39.8752 24.2182 39.5411 23.884 39.5411 23.123V13.8605L49.7502 24.2182H40.6548ZM44.5343 32.3298H31.1325C30.4271 32.3298 29.926 32.8309 29.926 33.4806C29.926 34.1488 30.4271 34.6686 31.1325 34.6686H44.5343C45.2025 34.6686 45.7037 34.1488 45.7037 33.4806C45.7037 32.8309 45.2025 32.3298 44.5343 32.3298ZM44.5343 38.5295H31.1325C30.4271 38.5295 29.926 39.0492 29.926 39.7174C29.926 40.3671 30.4271 40.8683 31.1325 40.8683H44.5343C45.2025 40.8683 45.7037 40.3671 45.7037 39.7174C45.7037 39.0492 45.2025 38.5295 44.5343 38.5295Z" fill="#FCB218"/> </svg> }
                            />
                        }
                    </Stack>
                </Stack>
            </RegModalWrapper>
        </>
    );
};


export default TeachingDocumentation;