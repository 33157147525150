import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./index.module.scss";

export type AlertSeverity = "error" | "warning" | "info" | "success";

interface IAlert {
  severity: AlertSeverity;
  message: string;
  visible: boolean;
  handleConfirm: (state: boolean) => void;
  handleCancel?: () => void;
  customizedConfirmText?: string;
}

const AlertComponent = ({
  severity,
  message,
  visible,
  handleConfirm,
  handleCancel,
  customizedConfirmText,
}: IAlert) => {
  return (
    <Dialog
      id={styles.AlertOnTopComponent__dialog}
      open={visible}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <div id={styles.alert_on_top_component}>
        <DialogTitle id={`${styles.alert_dialog_title} title_medium`}>
          {message}
        </DialogTitle>
        <DialogActions id={styles.AlertOnTopComponent__DialogActions}>
          <Button
            onClick={() => handleConfirm(false)}
            variant='contained'
            autoFocus
            color={severity}
            className={
              severity === "success"
                ? styles["AlertOnTopComponent__button--success"]
                : ""
            }
          >
            {customizedConfirmText ?? "Confirm"}
          </Button>
          {handleCancel ? (
            <Button
              onClick={() => {
                handleCancel();
              }}
              variant='contained'
              className={styles.cancelButton}
              autoFocus
            >
              Cancel
            </Button>
          ) : null}
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default AlertComponent;
