import { TabNames } from "../../routers/type";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export const HEADER_BUTTON_CONFIGS = [
  { text: "Teachers", link: `/${TabNames.TEACHERS}` },
  { text: "Schools", link: `/${TabNames.SCHOOLS}` },
  { text: "About", link: `/${TabNames.ABOUT}` },
  { text: "Shop", link: `/${TabNames.SHOP}` },
];

export const HEADER_DROPDOWN_CONFIGS = [
  { text: "Home", link: "/" },
  { text: "Teachers", link: `/${TabNames.TEACHERS}` },
  { text: "Schools", link: `/${TabNames.SCHOOLS}` },
  { text: "About", link: `/${TabNames.ABOUT}` },
  { text: "Shop", link: `/${TabNames.SHOP}` },
];

export const TEACHER_STATE_PAGES = [
  { name: 'vic', text: "Victoria", link: `/${TabNames.TEACHERS}/vic`},
  { name: 'nsw', text: "New South Wales", link: `/${TabNames.TEACHERS}/nsw`}
]

export const SCHOOL_STATE_PAGES = [
  { name: 'vic', text: "Victoria", link: `/${TabNames.SCHOOLS}/vic`},
  { name: 'nsw', text: "New South Wales", link: `/${TabNames.SCHOOLS}/nsw`}
]

export const HEADER_DROPDOWN_PRELOGIN_CONFIGS = [
  {
    text: "Login",
    link: `/${TabNames.LOGIN}`,
    customizedIcon: <AccountCircleIcon />,
  },
  {
    text: "Register",
    link: `/${TabNames.REGISTER}`,
    customizedIcon: <AccountCircleIcon />,
  },
];

export const HEADER_DROPDOWN_LOGIN_CONFIGS = [
  {
    text: "Dashboard",
    // link: `/${TabNames.OLD_PROJECT_URL}/${TabNames.DASHBOARD}`,
    link: `/${TabNames.DASHBOARD}`,
  },
];
