
export type SchoolInformation = {
    id?: string,
    account?: {
      createdBy?: string;
      dateRegistered?: string;
      registeredBy?: string;
      state?: string; 
    },
    businessManager?: {
      contact?: string;
      email?: string;
      invoiceEmail?: string;
      name?: string;
      state?: string;
      updatedBy?: string;
    },
    details?: {
      contactNumber?: string;
      educationType?: string;
      privateOrPublic?: string;
      religion?: string;
      schoolName?: string;
      schoolType?: string;
      updatedBy?: string;
    } & {
      contactType?: string;
    },
    location?: {
      address?: string;
      country?: string;
      lat?: number;
      locationState?: string;
      long?: number;
      postCode?: string;
      suburb?: string;
    },
    logo?: {
      state?: string;
      value?: string;
    },
    parking?: {
      address?: string;
      information?: string;
      lat?: number;
      long?: number;
      type?: string;
    },
    times?: {
      [start: string]: string;
    },
    primaryTimes?: {
      [start: string]: string;
    } & {
      same?: boolean;
    },
    secondaryTimes?: {
      [start: string]: string;
    } & {
      same?: boolean;
      primarySame?: boolean;
    },

    policies?: {
      documents?: {
        [policyId: string]: SchoolPolicy
      },
      publicUrl?: string;
      policyUrl?: string;
      timeUpdated?: number;
      updatedBy?: string;
    }
  }

export type SchoolPolicy = {
  id?:string;
  createdByUserUid?: string;
  timeCreated?: number;
  timeUpdated?: number;
  title?: string;
  updatedBy?: string;
  url?: string;

  publicUrl?: string;
  policyUrl?: string;
}

export type SchoolUser = {
  id?: string;
  deviceSettings?: {
    deviceType?: string;
    loggedIn?: boolean;
    version?: string;
  },
  notificationSettings?: SchoolUserNotifications;
  school?: {
    accountState?: string;
    authority?: string;
    dateRegistered?: string;
    positionTitle?: string;
    schoolCountry?: string;
    schoolState?: string;
    schoolUid?: string;
  },
  termsAndConditions?: {
    dateAccepted?: string;
    documentID?: string;
    timeAccepted?: number;
  },
  userDetails?: {
    contact?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
  }
}

export type SchoolLocations = {
    [id: string]: {
        g?: string;
        l?: any;
    }
}


export enum SchoolAccountState {
    PENDING='pendingApproval',
    APPROVED="approved",
    REMOVED="removed",
    ONHOLD="onHold"
}

export enum SchoolUserAccountState {
  PENDING="pendingSparkApproval",
  PENDING_ADMIN="pendingSchoolAdminApproval",
  APPROVED="approved",
  REMOVED="removed",
  DELETED="deleted"
}

export enum SchoolUserAuthority {
  ADMIN='admin',
  ASSISTANT='bookingAssistant'
}

export enum UserDataState {
    INCOMPLETE= 'incomplete',
    SUBMITTED='submitted',
    APPROVED='approved',
    EXPIRED='expired',
    REJECTED='rejected'
}

export enum SchoolType {
    PRIMARY='Primary',
    SECONDARY='Secondary',
    PRIMARY_SECONDARY='Primary & Secondary'
}

export enum SchoolSector {
    PRIVATE='Private',
    PUBLIC='Public'
}

export enum SchoolReligion {
    NONE='None',
    ANGLICAN='Anglican',
    BRETHREN='Brethren',
    CATHOLIC='Catholic',
    SECULAR='Secular',
    OTHER='Other'
}

export enum SchoolEducationType {
    COED='Co-Education',
    GIRLS='Girls Only',
    BOYS='Boys Only'
}

export type SchoolUserNotifications = {
  bookingConfirmations?: boolean;
  cancellationNotifications?: boolean;
  completedBookings?: boolean;
  isEnabled?: boolean;
  schoolUpdates?: boolean;
  sparkNews?: boolean;
}

export enum ParkingType {
  STAFF="Staff Car Park",
  ONSTREET="On Street Parking",
  PUBLIC="Public Car Park"
}

export type SchoolInvoice = {
  invoiceId?: string;
  amount?: number;
  dueDate?: string;
  invoiceDate?: string;
  invoiceNumber?: string;
  state?: SchoolInvoiceState;
}
export enum SchoolInvoiceState {
  ISSUED="issued",
  PAID="paid",
  OVERDUE="overdue"
}

export type SchoolInvoiceRequest = {
  email?: string;
  invoiceDueDate?: string;
  invoiceIssueDate?: string;
  invoiceNumber?: string;
  requestedBy?: string;
  schoolName?: string;
  timeStamp?: string;
  userUid?: string;
}