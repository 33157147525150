import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./index.module.scss";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import pxToRem from "../../helpers/pxToRem";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { TimeViewWithMeridiem } from "@mui/x-date-pickers/internals";
import { TimeValidationError } from "@mui/x-date-pickers/models";
import { Placement } from "react-bootstrap/esm/types";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack } from "@mui/material";

export const CustomizedDatePicker = styled(DatePicker)({
    "& .MuiOutlinedInput-root": {
            '&:hover > fieldset': {
            borderColor: styles.primaryYellow500,
            color: styles.primaryYellow500,
        },
        "&.Mui-focused fieldset": {
            borderColor: styles.primaryYellow500,
            color: styles.primaryYellow500,
        },
    },
    '&:hover label': {
        // color: styles.primaryYellow500,
    },
    '& label.Mui-focused': {
        color: styles.primaryYellow500,
        // color:'#00000099'
    },
})

export const CustomizedTimePicker = styled(TimePicker)({
    width: '100%',
    '&.MuiFormControl-root': {
        borderRadius: pxToRem(16),
        background: styles.backgroundPaperElevation0,
        boxShadow: `0px ${pxToRem(4)} ${pxToRem(4)} 0px rgba(0, 0, 0, 0.25)`,
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: pxToRem(16),
        padding:0,
        'fieldset': {
            border: 'none',
        },
        '& .MuiOutlinedInput-input': {
            textAlign:'center',
            color: styles.neutrals500,
            fontSize: pxToRem(18),
            fontWeight: 400,
            letterSpacing: pxToRem(-0.36),
            lineHeight: pxToRem(28),
            padding: `${pxToRem(8)} ${pxToRem(14)}`,
        }
    },
})

interface IControlledDatePicker {
    value?: dayjs.Dayjs | null;
    minTime?: dayjs.Dayjs | null;
    maxTime?: dayjs.Dayjs | null;
    onChange?: (e: dayjs.Dayjs | null) => void;
    error?: TimeValidationError;
    errorMsg?: string;
    onError?: (e: TimeValidationError) => void;
    openTo?: string;
    placement?: Placement;
}
export const ControlledDatePicker = ({ value, minTime, maxTime, onChange, error, errorMsg, onError, openTo, placement }: IControlledDatePicker) => {
    const [open, setOpen] = useState(false);
    return (
        <Stack sx={{position:'relative', height: '100%', width: '100%'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}
                localeText={{
                    fieldHoursPlaceholder: () => '--',
                    fieldMinutesPlaceholder: () => '--',
                    fieldMeridiemPlaceholder: () => '',
                }}
                
            >
                <CustomizedTimePicker value={value} onChange={onChange} openTo={openTo as TimeViewWithMeridiem || "hours"}
                    open={open}
                    onClose={()=>setOpen(false)}
                    minTime={minTime ? minTime : undefined} maxTime={maxTime ? maxTime : undefined} 
                    onError={onError}
                    slotProps={{
                        textField: {
                            // disabled: true,
                            helperText: error && errorMsg,
                            FormHelperTextProps: {
                                className: styles.datePickerErrorMsg
                            }
                        },
                        popper: {
                            className: styles.datePickerPopper,
                            placement: placement || 'bottom-start'
                        },
                        inputAdornment: {
                            onClick: () => { setOpen(!open) },
                            className: styles.datePickerAdornment
                        }
                    }}
                />
            </LocalizationProvider>
            <div style={{position:'absolute', height: '100%', width: '90%', top: 0, left: 0, background:'transparent'}} onClick={()=>setOpen(!open)}/>
        </Stack>
    )
}

// export const CustomizedTimePicker = styled(MobileDatePicker)({
//     width: '100%',
//     '&.MuiFormControl-root': {
//         borderRadius: pxToRem(16),
//         background: styles.backgroundPaperElevation0,
//         boxShadow: `0px ${pxToRem(4)} ${pxToRem(4)} 0px rgba(0, 0, 0, 0.25)`,
//     },
//     '& .MuiOutlinedInput-root': {
//         borderRadius: pxToRem(16),
//         padding:0,
//         'fieldset': {
//             border: 'none',
//         },
//         '& .MuiOutlinedInput-input': {
//             textAlign:'center',
//             color: styles.neutrals500,
//             fontSize: pxToRem(18),
//             fontWeight: 400,
//             letterSpacing: pxToRem(-0.36),
//             lineHeight: pxToRem(28),
//             padding: `${pxToRem(8)} ${pxToRem(14)}`,
//         }
//     }
// })