import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { getTermsData, getUpcomingTerms } from "../../firebase/general-apis";
import styles from "./index.module.scss";
import Box from "@mui/material/Box";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import AlertComponent from "../../components/AlertComponent";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Term } from "../../firebase/types";
import {FutureTerm, TermData} from "../../components/TermsPreviewBox/types";
import TermsPreviewBox from "../../components/TermsPreviewBox";
import moment from "moment";

export const sortTermsBasedOnRank = (a: [string, Term], b: [string, Term]) => {
  const [keyA, termA] = a;
  const [keyB, termB] = b;

  if (termA.rank < termB.rank) {
    return -1;
  }
  if (termA.rank < termB.rank) {
    return 1;
  }
  return 0;
};

const TermsOfServicePage = () => {
  const [terms, setTerms] = useState<TermData[]>([]);
    const [futureTerms, setFutureTerms] = useState<FutureTerm[]>([]);
    const [upcomingTerms, setUpcomingTerms] = useState<
    { title: string; terms: TermData[] }[]
  >([]);
  const [alertCompVis, setAlertCompVis] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const screenType = useSelector((state: RootState) => state.ui.screenType);

  useEffect(() => {
    getTermsData()
      .then((termsData) => {
          const terms = Object.entries(termsData)
              .sort(sortTermsBasedOnRank)
              .map(([uid, term]) => ({ ...term, uid }))
        setTerms(
          terms.filter( term => {
                  const effectiveDateTimeStamp = moment(term.effectiveDate,'DD-MM-YYYY').unix();
                  const expiryDateTimeStamp = moment(term.expiryDate, 'DD-MM-YYYY').unix();
                  return effectiveDateTimeStamp < moment().unix() && (term.expiryDate.length === 0 || expiryDateTimeStamp > moment().unix())
              })
        );
          setFutureTerms(
              terms.filter(term => {
                  const effectiveDateTimeStamp = moment(term.effectiveDate,'DD-MM-YYYY').unix();
                  return effectiveDateTimeStamp > moment().unix()
              })
          )
        setLoadingData(false);
      })
      .then(() => {
        getUpcomingTerms()
          .then((upComingTerms) => {
            setUpcomingTerms(
              Object.entries(upComingTerms).map(([key, upcomingTerm]) => ({
                title: upcomingTerm.title,
                terms: Object.entries(upcomingTerm.terms)
                  .sort(sortTermsBasedOnRank)
                  .map(([uid, term]) => ({ ...term, uid })),
              }))
            );
          })
          .catch((e) => {
            console.log(
              "Errors happen when fetching upcoming terms of service data"
            );
            console.log(e);
            setLoadingData(false);
            setAlertCompVis(true);
          });
      })
      .catch((e) => {
        console.log("Errors happen when fetching terms of service data");
        console.log(e);
        setLoadingData(false);
        setAlertCompVis(true);
      });
  }, []);

  const render = () => {
      console.log(terms)
      console.log(upcomingTerms)
      if (loadingData) {
      return (
        <Stack
          className={`flex_row_center  ${styles.TermsOfServicePage} fill_screen_height`}
        >
          <LoadingSpinner />
        </Stack>
      );
    }

    return (
      <>
        <Header />
        <AlertComponent
          severity='error'
          message='Unfortunately, errors happen when fetching terms of service data. Please refresh and try again.'
          visible={alertCompVis}
          handleConfirm={() => {
            setAlertCompVis(false);
            navigate("/");
          }}
        />
        <Stack className={`flex_row_center  ${styles.TermsOfServicePage}`}>
          <Stack className={`${styles.container} fill_screen_height`}>
            <Box
              className={`${
                screenType === "PC"
                  ? "display_small bold_weight"
                  : "title_large semi_bold_weight"
              } space_for_header ${styles.title}`}
            >
              Terms of Registration
            </Box>
            <TermsPreviewBox
              termsConfig={terms}
              upcomingTermsConfig={[{title:'Upcoming Terms', terms: futureTerms}]}
            />
          </Stack>
        </Stack>
        <Footer />
      </>
    );
  };

  return render();
};

export default TermsOfServicePage;
